.from-jazz-to-vialma {
  margin: -20px;
  max-width: 640px;
  .header {
    background: rgba(72, 102, 224, 0.1);
    padding: 0px 140px;
    img {
      position: relative;
      top: 40px;
      width: 100%;
    }
  }
  .close{
    color: $grey_dark;
    cursor: pointer;
    text-align: center;
    padding: $size_14 0; 
  }
  .content-modal {
    padding: 40px 80px;
    .head-txt {
      font-size: 20px;
      line-height: 35px;
      text-align: center;
      padding: 32px 0;
      span {
        color: $blue;
        font-weight: bold;
      }
    }
    .btn-blue {
      width: 264px;
      height: 48px;
      border-radius: 100px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-weight: bold;
      background: $red;
      margin-bottom: 24px;
      cursor: pointer;
    }
    .subheader {
      text-align: center;
      span {
        color: $red;
        cursor: pointer;
      }
    }
  }
  &.classic {
    .header {
      background: #fff4ea;
    }
    .content-modal {
      .head-txt {
        span {
          color: $yellow;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .from-jazz-to-vialma {
    margin: -20px;
    .header {
      padding: 0;
      text-align: center;
      img {
        margin: 0 auto 30px auto;
      }
    }
  }
}
