@import "./constants.scss";

.icon-close-terms {
  display: block;
  position: fixed;
  top: 14px;
  right: 16px;
  cursor: pointer;
}
.terms-modal {
  max-width: 800px;
  max-height: 40vw;
  padding: 10px;
  overflow: auto;
  margin-top: 36px;
  h2 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0;
  }
  p {
    margin-bottom: 20px;
    line-height: 20px;
  }
  strong {
    font-weight: bold;
  }
  a,
  .link {
    color: $red;
    cursor: pointer;
  }
  ul {
    margin-left: 20px;
    margin-bottom: 30px;
    ul {
      margin-bottom: 0;
    }
  }
  li {
    list-style-type: disc;
    margin: 10px 0;
  }
  ol {
    margin-left: 20px;
    margin-bottom: 30px;
    li {
      list-style: upper-alpha;
    }
  }
  table {
    width: 100%;
    border: 1px solid $grey_light;
    margin-bottom: 20px;
    th {
      font-weight: bold;
    }
    th,
    td {
      border: 1px solid $grey_light;
      padding: 8px;
      text-align: left;
      line-height: 20px;
      width: 50%;
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .terms-modal {
    max-height: none;
    padding: 0;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0px;
    right: 0px;
    padding: 0px 16px;
    margin-top: 56px;
    .icon-close {
      display: block;
      position: absolute;
      top: -42px;
      right: -7px;
    }
  }
}
