.offer-modal {
  background: url("../assets/images/big_star.svg") right top no-repeat; // fallback
  background: url("../assets/images/big_star.svg") right top no-repeat, linear-gradient($white 5%, $red_light 100%);
  &.jazz {
    background: url("../assets/images/big_star.svg") right top no-repeat, linear-gradient($white 5%, $blue_light 100%);
  }

  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 20px;
  }
  .offer-modal-header {
    line-height: 30px;
    text-align: center;
    margin-bottom: 10px;
    word-wrap: break-word;
  }
  .plan-bullets {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  &-top,
  &-bottom {
    position: relative;
    padding: 60px 20px 20px 20px;

    .big-star,
    .small-star-right,
    .small-star-left {
      position: absolute;
      z-index: 0;
    }
    .big-star {
      right: 0;
      top: -50%;
      width: 90%;
    }
    .small-star-left {
      top: 20px;
      left: 20px;
    }
    .small-star-right {
      top: -20px;
      right: 20px;
    }
  }
  &-bottom {
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 40px;
  }

  .offer-modal-header {
    font-family: "Playfair Display", serif;
  }

  .close {
    color: $grey_dark;
    cursor: pointer;
    padding: $size_14 0;
    &.right-align {
      text-align: right;
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .offer-modal {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    &.from-jazz-to-vialma {
      margin: 0;
      max-width: unset;
    }
    &-top,
    &-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }
    &-top {
      padding-top: 100px;
      flex: 2;
    }
    &-bottom {
      flex-grow: 1;
    }
  }
}

.plan-bullets {
  padding: 12px 0;
  img {
    width: 24px;
    height: 24px;
  }
  li {
    line-height: 24px;
    font-size: 16px;
    color: $black;
    text-align: left;
    position: relative;
    margin-bottom: 7px;
    display: flex;
    align-content: center;
    word-wrap: break-word;
    img {
      margin-right: 10px;
    }
  }
}
