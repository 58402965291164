.articles {
  .loading-icon {
    position: absolute;
    top: 324px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading-articles {
    margin-top: -80px;
    display: flex;
    justify-content: center;
  }

  .articles-title {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-style: normal;
    font-size: 42px;
    color: $white;
  }
  .articles-desc {
    line-height: 28px;
    color: $white;
    max-width: 450px;
    margin-top: 20px;
  }
  .category-name {
    display: none;
  }
  .head-category {
    padding: 30px 0;
    box-shadow: inset 0px -1px 0px $grey_vlight;
    margin-bottom: 48px;
    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 28px;
      margin-bottom: 24px;
      .icon-category {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }
  .container-categories {
    padding: 0 48px;
    position: relative;
    &.no-space {
      padding: 0;
    }
    .icon-slide-prev,
    .icon-slide-next {
      position: absolute;
      z-index: 2;
      top: 4px;
      cursor: pointer;
    }
    .icon-slide-prev {
      left: 0;
    }
    .icon-slide-next {
      right: 0;
    }
    .categories-box {
      overflow: hidden;
      padding-bottom: 5px; 
    }
    .menu-scroller {
      display: flex;
      flex-direction: row;
      transition: all 0.5s;
    }
    .cat-item {
      border: 1px solid $black;
      color: $black;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 11px 30px;
      border-radius: 100px;
      margin-right: 16px;
      cursor: pointer;
      white-space: nowrap;
      &.selected {
        background-color: $black;
        color: $white;
      }
    }
  }
  .list-articles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
    flex-wrap: wrap;
    &.no-space-bottom {
      margin-bottom: 0;
    }
    > a {
      width: 32%;
      margin-bottom: 48px;
      cursor: pointer;
      text-decoration: none;
      color: $black;
      display: block;
      padding: 16px;
      &.feature {
        border: 1px solid $grey_vlight;
      }
      &:hover {
        box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
      }
      .label {
        position: relative;
        top: -16px;
        border-radius: 0px 0px 4px 4px;
        background: $red;
        padding: 2px 8px;
        font-size: 14px;
        line-height: 20px;
        color: $white;
        display: inline-block;
      }
      img {
        width: 100%;
        max-height: 250px;
        min-height: 250px;
        object-fit: cover;
      }
      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        padding: 16px 0 10px 0;
      }
      .desc {
        line-height: 28px;
      }
    }
  }
  .mobile-select-categories {
    display: none;
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .articles {
    .list-articles {
      > a {
        width: 48%;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .articles {
    .loading-articles {
      margin-top: 0;
    }
    .content {
      margin: 0;
    }
    .head-category {
      padding: 16px 0;
      margin: 0 0 16px 0;
      .title {
        margin-bottom: 0;
      }
    }
    .list-articles {
      margin-bottom: 0px;
      > a {
        margin-bottom: 24px;
        width: 100%;
      }
    }
    .category-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $red;
      margin-left: 10px;
      cursor: pointer;
      .arrow-category {
        margin-left: 8px;
        width: 10px;
        height: 8px;
      }
    }
    .modal-category {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      z-index: 21;
      .head {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $grey_vlight;
        height: 56px;
        position: relative;
        img {
          position: absolute;
          top: 50%;
          margin-top: -16px;
          right: 16px;
          cursor: pointer;
        }
      }
      .apply {
        position: absolute;
        bottom: 0;
        height: 72px;
        width: 100%;
        border-top: 1px solid $grey_vlight;
        display: flex;
        justify-content: center;
        align-items: center;
        .button-apply {
          display: flex;
          justify-content: center;
          align-items: center;
          background: $red;
          border-radius: 100px;
          width: 100%;
          color: $white;
          margin: 0 16px;
          height: 40px;
          cursor: pointer;
        }
      }
    }
    .container-categories {
      display: none;
    }
    .mobile-select-categories {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $red;
      margin-left: 10px;
      cursor: pointer;
      img {
        margin-left: 10px;
      }
    }
  }
  .modal-categories {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    &__head {
      position: relative;
      height: 56px;
      box-shadow: inset 0px -1px 0px #dcdfe4;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .close-modal-categories {
      position: absolute;
      top: 12px;
      right: 10px;
      cursor: pointer;
    }
    &__middle {
      position: absolute;
      top: 56px;
      bottom: 72px;
      left: 0;
      right: 0;
      overflow: auto;
      .cat-item {
        margin: 16px;
        border: 1px solid $black;
        border-radius: 100px;
        padding: 12px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        &.selected {
          background: $black;
          color: $white;
        }
      }
    }
    &__bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: inset 0px 1px 0px #dcdfe4;
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      .btn-red {
        background: $red;
        border-radius: 100px;
        width: 100%;
        height: 40px;
        font-weight: bold;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
