$greyLight: #eeeeee;
$white: #ffffff;
$black: #141140;
$border: #bbbfc7;
$red: #dd4e52;
$red_light: #FEE8E8;
$grey_light: #8f99aa;
$red_black: #cb0014;
$grey_mlight: #f4f4f4;
$grey_vlight: #dcdfe4;
$grey_dark: #929292;
$brown: #ebae71;
$blue: #4866e0;
$blue_dark: #3c52aa;
$blue_light: #4866e038;
$green: #4ad1b1;
$purple: #ae71eb;
$yellow: #ffc439;
$classical: #8c283d;

// @media
$breakpoint_1400: 1400px;
$breakpoint_1200: 1200px;
$breakpoint_1024: 1024px;
$breakpoint_880: 880px;
$breakpoint_768: 768px;
$breakpoint_658: 658px;
$breakpoint_518: 518px;
$breakpoint_min: 1px;

//font sizes 
$size_14: 14px;
$size_15: 15px;
$size_16: 16px;
$size_18: 18px;
$size_20: 20px;
$size_24: 24px;
$size_32: 32px;
$size_40: 40px;
$size_42: 42px;

//font families
$playfair: "Playfair Display", serif;
$roboto: "Roboto", sans-serif;

$card_border_radius: 5px;
