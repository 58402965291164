.vialma-toast {
  display: flex;
  flex-direction: row;
  background: #141140;
  box-shadow: 0px 0px 12px rgba(20, 17, 64, 0.2);
  color: white;
  height: auto;
  padding: 10px;
  align-items: center;

  &__icon {
    padding-right: 5px;
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    padding-right: 5px;

    a {
      cursor: pointer;
      font-weight: bold;
      text-decoration: underline;
      color: white;
    }
  }

  &__X {
    color: #8f99aa;
    padding-left: 15px;
    margin-left: auto;
    cursor: pointer;
  }
}

.notification {
  // Not possible to "fit-content" via library API (only to set pixels), so doing it here.
  width: fit-content !important;  
}
