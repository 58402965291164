.simpleLogin {
  width: 448px;
  height: 466px;
  background: $white;
  padding: 40px;
  margin: auto;
  
  @media only screen and (max-width: $breakpoint_658) {
    width: 100%;
  }

  
  .header-login {
    font-size: 32px;
    line-height: 45px;
    color: $black;
    &.space-bottom {
      margin-bottom: 24px;
    }
  }

  input,
  .submit {
    width: 100%;
  }

  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading-icon {
    position: absolute;
  }
  .forgot-password {
    margin: 10px 0 26px 0;
    display: inline-block;
    color: $red;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}