.streamQualityPopup {
  margin: 0 auto;         
  .icon-close {
    display: block;
    position: fixed;
    top: 14px;
    right: 16px;
    cursor: pointer;
  }

  .content-wrapper {
    max-width: 480px;
    padding: 10px;
    overflow: auto;
    margin-top: 36px;
    font-family: "Roboto", sans-serif;

    .stream-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #141140;
      padding-bottom: 16px;
    }

    .stream-description {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #141140;
      padding-bottom: 24px;
    }

    .stream-allvialma-url {
      text-align: center;
      margin-top: -24px;
      padding-bottom: 24px;
      color: #ebae71;
      font-size: 16px;
      line-height: 28px;
      text-decoration-line: underline;
      cursor: pointer;
    }

    .stream-button-wrapper {
      display: flex;
      justify-content: center;

      button {
        min-width: 120px;
        margin: 10px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        justify-content: center;
      }
    }

    .stream-dontshow-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 14px;
      padding-bottom: 24px;
      cursor: pointer;

      input {
        margin-right: 20px;
        margin-bottom: 3px;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 135%;
        display: flex;
        align-items: center;
        color: #141140;
        padding-left: 8px;
      }
    }

    .stream-footer-notification {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #8f99aa;
    }
  }
}
