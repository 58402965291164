@import "./constants.scss";

.app-banner {
  padding: 16px 10px;
  height: 104px;
  background: $grey_mlight;
  display: none;
  flex-direction: row;
  @media only screen and (max-width: $breakpoint_658) {
    height: unset;
  }
  .logo-app {
    width: 72px;
    height: 72px;
    min-width: 72px;
    min-height: 72px;
    border-radius: 10px;
    background: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 13px;
  }
  .header {
    font-family: "Playfair Display", serif;
    font-size: 22px;
    line-height: 29px;
    white-space: nowrap;
    @media only screen and (max-width: $breakpoint_658) {
      white-space: unset;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
  }
  .button-try {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    border: 1px solid $red;
    color: $red;
    font-size: 14px;
    font-weight: bold;
    padding: 0 20px;
    border-radius: 100px;
    margin-right: 22px;
    text-align: center;
    cursor: pointer;
  }
  .link {
    color: $red;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
}
.header-vialma-space {
  height: 68px;
  min-height: 68px;
}
@media only screen and (max-width: 600px) {
  .header-vialma-space {
    height: 60px;
    min-height: 60px;
  }
}
.header-vialma-space-free {
  height: 116px;
  min-height: 116px;
}
.header-vialma {
  position: fixed;
  top: 0;
  width: 100%;
  background: $white;
  z-index: 20;
}
.menu-desktop {
  display: flex;
  max-width: 1120px;
  margin: 0 auto;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 2;
  background: $white;
  height: 68px;
}

.logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .logo-jazz-classical {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border: 0;
    margin-left: 0 11px;
    color: $red_black;
    &:visited {
      color: $red_black;
    }
    &.jazz {
      color: $blue;
      &:visited {
        color: $blue;
      }
    }

    &:focus {
      outline: none;
    }
  }

  .logo-vialma {
    padding: 22px 0;
  }

  .universe-selection-container {
    display: flex;
    flex-direction: row;
    margin-left: 35px;
    align-items: center;
    position: relative;
    cursor: pointer;

    &:hover {
      .alt-universe {
        padding-right: 10px;
        background: url("../assets/images/classical_person_light.svg") top left -20px no-repeat;
        background-color: $red;
        background-size: 60px;
        color: $white;
        box-shadow: 0px 10px 10px rgba(143, 153, 170, 0.3);

        span {
          margin-left: -5px;
          font-weight: bold;
          color: $white;
        }
        img {
          opacity: 1;
          margin-left: 5px;
          filter: none;
        }
        &.jazz {
          background: url("../assets/images/jazz_person.svg") top left no-repeat;
          background-size: 40px;
          background-color: $blue_dark;
          img {
            filter: none;
          }
        }
      }
    }
    .universe-selection-button,
    .alt-universe {
      justify-content: center;
      transition: all 200ms;
      color: $red;
      background-color: transparent;
      &.jazz {
        color: $blue;
        background-color: transparent;
      }
      span {
        font-weight: bold;
        transition: all 200ms;
      }
    }
    .universe-selection-button {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      padding: 12px 15px;
      min-width: 90px;
    }
    .alt-universe {
      text-transform: uppercase;
      font-weight: normal;
      padding-right: 12px;
      justify-content: center;
      display: flex;
      align-items: center;
      background-size: 100px;
      &.jazz {
        background-color: $white;
        background-size: 100px;
      }
      img {
        height: 10px;
        transition: all 200ms;
        margin-left: -10px;
        opacity: 0;
        filter: none;
        @media only screen and (max-width: $breakpoint_880) {
          margin-left: 5px;
          opacity: 1;
          filter: invert(39%) sepia(79%) saturate(704%) hue-rotate(313deg) brightness(90%) contrast(93%);
        }
      }
      &.jazz {
        img {
          @media only screen and (max-width: $breakpoint_880) {
            filter: invert(55%) sepia(64%) saturate(2544%) hue-rotate(207deg) brightness(99%) contrast(90%);
          }
        }
      }
    }

    @media only screen and (max-width: $breakpoint_658) {
      margin-left: 0px;
    }

    .arrow-down {
      margin-left: 10px;
    }

    &:hover,
    &.show-universes {
      .universe {
        display: block;
      }
    }

    .universe {
      box-shadow: 0px 4px 4px rgba(20, 17, 64, 0.2);
      background: #fff;
      position: absolute;
      top: 60px;
      width: 240px;
      display: none;
      margin-left: 22px;

      &__item {
        padding: 15px 20px;
        &:hover {
          background: rgba(20, 17, 64, 0.1);
        }

        &.classical {
          color: $red;
        }

        &.jazz {
          color: $blue;
        }
      }
    }
  }
}

.menu-vialma {
  display: flex;
  flex-direction: row;
  align-items: center;

  .item {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: inherit;
    > a {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $black;
      padding: 22px 0;
      &:hover {
        text-decoration: none;
      }

      &.composerArtist {
        width: 100px;
        height: 100px;
      }
    }
    &.account {
      padding: 22px 0;
    }
    &.browse {
      padding: 22px 0;
    }
    &.lang-btn {
      text-decoration: none;
      color: inherit;
    }
    cursor: pointer;
    span {
      margin-left: 10px;
    }
    &.menu-icon {
      display: none;
    }
    &:hover {
      .sub-menu {
        display: block;
      }
    }
    .sub-menu {
      box-shadow: 0px 4px 4px rgba(20, 17, 64, 0.2);
      background: #fff;
      position: absolute;
      top: 66px;
      width: 240px;
      display: none;
      a {
        color: $black;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
      &__item {
        padding: 15px 20px;
        margin: auto;
        &:hover {
          background: rgba(64, 17, 17, 0.1);
        }

        &.withIcon {
          display: flex;
          align-items: flex-end;

          > img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }

        &.active {
          color: $red;
          a {
            color: $red;
          }
          &.jazz {
            color: $blue;
            a {
              color: $blue;
            }
          }
        }

        a {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .arrow-down {
    margin-left: 4px;
  }
}

.mobile-menu {
  background: #fff;
  position: relative;
  z-index: 1;
  top: 0px;
  left: 0;
  right: 0;
  height: 0px;
  overflow: hidden;
  transition: all 0.2s;
  &.expanded {
    height: 300px;
    border-top: 1px solid $grey_vlight;
    box-shadow: 0px 4px 4px rgba(20, 17, 64, 0.2);
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    color: $black;
    text-decoration: none;
    cursor: pointer;

    a {
      color: inherit;
      text-decoration: none;
    }

    &.active {
      color: $red;
      cursor: default;
      a {
        color: $red;
        cursor: default;
      }
      &.jazz {
        color: $blue;
        a {
          color: $blue;
        }
      }
    }

    &.withIcon {
      display: flex;
      align-items: flex-end;

      > img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    img {
      margin-right: 10px;
    }
  }
}
.free-trial-bar,
.event-banner {
  background: $red_light;
  height: 48px;
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__left,
  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
  }
  &__left {
    font-weight: bold;
    img {
      margin-right: 16px;
    }
  }
  .btn-upgrade {
    background: $red;
    border-radius: 100px;
    color: $white;
    font-weight: bold;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    text-align: center;
    padding: 20px;
    &:hover {
      text-decoration: none;
    }
  }
}

.event-banner {
  background: $red;
  height: unset;
  padding: 12px 0;

  .content {
    @media screen and (max-width: $breakpoint_658) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &__left {
    color: $white;
  }
  .btn-upgrade {
    background: $red_light;
    border-radius: 100px;
    color: $red;
  }
  .closeIcon {
    cursor: pointer;
    position: absolute;
    right: 0;
    margin-left: 10px;
    margin-right: 10px;
    @media only screen and (max-width: $breakpoint_1200) {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: $breakpoint_1200) {
  .menu-desktop {
    max-width: 100%;
    padding: 0 30px;
  }

  .menu-vialma {
    .library,
    .composer,
    .artist,
    .browse,
    .home,
    .account {
      display: none;
    }
    .item {
      margin-left: 20px;
      span {
        display: none;
      }
      &.menu-icon {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .menu-desktop {
    padding: 0 16px;
  }
  .menu-vialma {
    .item {
      margin-left: 20px;
      span {
        display: none;
      }
      &.subscribe-link,
      &.jazz-classical,
      &.giftcard-link,
      &.helpdesk-link {
        display: none;
      }
    }
  }
  .free-trial-bar {
    font-size: 13px;
    .content {
      padding-left: 8px;
      padding-right: 8px;
    }
    &__left {
      img {
        margin-right: 8px;
      }
    }
    .btn-upgrade {
      padding: 0 10px;
    }
  }
  .app-banner {
    display: flex;
    .button-try {
      font-size: 13px;
    }
  }
}
