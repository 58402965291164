.form-register {
  .free-trial-box {
    width: 100%;
    .submit {
      &.blue {
        background: $blue;
      }
      &.green {
        background: $green;
      }
      span {
        &.white {
          color: $white;
        }
        &.black {
          color: $black;
        }
      }
    }
    .login,
    .terms {
      &.blue {
        span {
          color: $blue;
        }
      }
      &.green {
        span {
          color: $green;
        }
      }
    }
  }
}
.login-modal {
  .forgot-password {
    &.blue {
      color: $blue;
    }
    &.green {
      color: $green;
    }
  }
  .submit {
    &.blue {
      background: $blue;
    }
    &.green {
      background: $green;
    }
    span {
      &.white {
        color: $white;
      }
      &.black {
        color: $black;
      }
    }
  }
  .no-account {
    span {
      &.blue {
        color: $blue;
      }
      &.green {
        color: $green;
      }
    }
  }
}
