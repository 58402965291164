.NewsFeed {
  width: 100%;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation: fadeIn 1s ease both;
  animation-iteration-count: 1;

  .NewsTitle {
    font-family: "Playfair Display", serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    padding: 115px 0px 48px 0px;
  }

  .NewsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0% 3%;

    @media only screen and (max-width: $breakpoint_880) {
      grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: $breakpoint_518) {
      grid-template-columns: 1fr;
    }
  }
}

.HeroSection {
  border: 1px solid #dcdfe4;
  width: 100%;
  padding: 16px;
  background-color: white;
  display: flex;
  margin-bottom: 32px;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
  }

  @media only screen and (max-width: $breakpoint_880) {
    flex-direction: column;
    padding: 0px;
  }

  &__ImageSection {
    max-width: 528px;
    max-height: 288px;
    @media only screen and (max-width: $breakpoint_880) {
      order: 2;
      max-width: 100%;
      max-height: 100%;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__VideoSection {
    position: relative;
    padding-bottom: 280px;
    width: 500px;
    height: 0;

    @media only screen and (max-width: $breakpoint_880) {
      order: 2;
      max-width: 100%;
      max-height: 100%;
      padding-bottom: 56.25%;
      width: unset;
    }

    iFrame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .LinkContainerMobile {
    display: none;
    order: 3;
    padding: 16px;
    & button {
      font-family: "Roboto", sans-serif;
      display: flex;
      margin-top: 0px;
      padding: 12px 16px;
      font-size: 14px;
      align-items: center;
      margin-right: 20px;

      & img {
        margin-right: 10px;
      }
    }

    .Clickthrough-Text {
      font-family: "Roboto", sans-serif;
      color: $red;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      padding: 12px 0px;
      &.jazz {
        color: $blue;
      }
    }

    @media only screen and (max-width: $breakpoint_880) {
      display: flex;
      align-items: center;
    }
  }

  &__ContentSection {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    flex: 1;

    @media only screen and (max-width: $breakpoint_880) {
      order: 1;
      padding: 16px;
      margin: 0;
    }

    & h3 {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 10px;
    }

    & p {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 24px;
      flex-grow: 1;
    }

    & .LinkContainer {
      display: flex;
      align-items: center;
      @media only screen and (max-width: $breakpoint_880) {
        display: none;
      }

      & button {
        font-family: "Roboto", sans-serif;
        display: flex;
        margin-top: 0px;
        padding: 12px 16px;
        font-size: 14px;
        align-items: center;
        font-weight: 500;
        height: 40px;
        margin-right: 20px;

        & img {
          margin-right: 10px;
          max-height: 24px;
        }
      }

      .Clickthrough-Text {
        font-family: "Roboto", sans-serif;
        color: $red;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        padding: 12px 0px;
        &.jazz {
          color: $blue;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.NewsTile {
  border: 1px solid #dcdfe4;
  background-color: white;
  display: flex;
  flex-direction: column;
  color: #3d3c4b;
  cursor: pointer;
  margin-bottom: 20px;

  @media only screen and (max-width: $breakpoint_768) {
    max-width: 100%;
    margin-bottom: 40px;
  }

  &:hover {
    text-decoration: none;
    box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);

    .picture-wrapper {
      div,
      img {
        &.playIcon {
          opacity: 1;
        }
      }
    }
  }

  &__Info {
    padding: 16px;
    flex-grow: 1;

    & h4 {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 10px;
    }

    & p {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .picture-wrapper {
    position: relative;

    img,
    div {
      &.picture {
        height: 200px;
        width: 100%;
        object-fit: cover;
      }
      &.playIcon {
        position: absolute;
        right: 24px;
        bottom: 18px;
        @extend .play-icon-extend;

        &.initialising {
          opacity: 1;
        }

        img {
          width: 48px;
          height: 48px;
        }

        @media only screen and (max-width: $breakpoint_1024) {
          display: block;
          opacity: 1;
        }
      }
    }
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 200px;
    height: 0;
    background: black;

    @media only screen and (max-width: $breakpoint_768) {
      max-width: 100%;
      max-height: 100%;
      width: unset;
      padding-bottom: 56.25%;
    }

    iFrame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.AuthorComponent {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
  }

  & p {
    margin-left: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 14px;
  }
}
