@import "./constants.scss";

.footer {
  background: $grey_mlight;
  overflow-x: hidden;
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 120px;
    color: $grey_light;
    padding-top: 30px;
    padding-bottom: 70px;
    &__left,
    &__right {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      .logo-foot {
        width: 64px;
        height: 28px;
        margin-right: 45px;
      }
    }

    &__left {
      align-items: flex-start;
    }
    &__right {
      align-items: flex-end;
    }
  }
  .menu-foot,
  .menu-foot-lower {
    line-height: 30px;
    text-align: right;
    a,
    span {
      font-size: 14px;
      display: inline-block;
      color: $grey_light;
      cursor: pointer;
    }
    a:not(.no-link),
    span:not(.no-link) {
      &:hover {
        text-decoration: underline;
        color: $red;
      }
    }
    &.jazz {
      a:not(.no-link),
      span:not(.no-link) {
        &:hover {
          color: $blue;
        }
      }
    }
  }
  .menu-foot {
    a,
    span {
      margin-left: 30px;
    }

    a:first-child,
    span:first-child {
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .footer {
    .menu-foot {
      line-height: 16px;
      a,
      span {
        margin-right: 0;
      }
    }
    .logo-foot {
      margin-right: 25px;
    }
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .footer {
    .content {
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      &__left {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
      }
      &__right {
        align-items: flex-start;
      }
    }
    .menu-foot-lower {
      line-height: unset;
    }
    .menu-foot,
    .menu-foot-lower {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .menu-foot-pipe {
        display: none;
      }
      a,
      span {
        margin-bottom: 20px;
        font-size: 16px;
        margin-left: 0;
      }
    }
  }
}

.text-left {
  text-align: left;
}
