.cancelSubscription {
  background-color: $grey_mlight;
  position: relative;
  min-height: 90vh;
  padding-bottom: 10vh;

  &__container {
    padding: 50px;

    @media only screen and (max-width: $breakpoint_1024) {
      padding: 30px;
    }

    @media only screen and (max-width: $breakpoint_658) {
      padding: 20px;
    }
  }

  .hidden {
    display: none;
  }

  &__title {
    border-bottom: $grey_vlight solid 2px;
  }

  &__title,
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: $size_14 0;
    text-transform: capitalize;

    .title {
      font-weight: bold;
    }
  }

  .cancelSub-button,
  .back-button,
  .next-button {
    width: 114px;
    height: 39px;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: $size_16;
    border-radius: 100px;
    font-style: normal;
    font-weight: 400;
    border: 1px solid $black;
    color: $black;
    text-decoration: none;
    cursor: pointer;

    &:focus {
      border: 1.5px solid black;
    }
  }

  .cancelSub-button {
    background-color: $black;
    color: $white;
    width: max-content;

    &.centered {
      margin: 0 auto;
    }
  }

  &__form-buttons {
    display: flex;
    justify-content: space-between;

    .next-button {
      cursor: pointer;

      &.disabled {
        background-color: $grey_vlight;
        color: $grey_light;
        border: 0px;
        cursor: default;
      }
      &.step-2 {
        width: unset;
        border: none;
        color: $black;
        background-color: transparent;
      }
      &.hide {
        display: none;
      }
    }
  }

  .link-benefits {
    display: none;

    @media only screen and (max-width: $breakpoint_658) {
      display: block;
      color: $red;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }

  .plans-page {
    width: 100%;
  }

  .form,
  .plans-page {
    .__heading,
    .__footing {
      text-align: center;

      .__header,
      .__subheader {
        margin-bottom: $size_32;
      }

      .__header {
        font-family: "Playfair Display", serif;
        font-size: $size_32;

        @media only screen and (max-width: $breakpoint_658) {
          font-size: $size_20;
        }
      }
      .__subheader {
        font-size: $size_18;
      }
    }
    .__footing {
      padding: $size_15 0;
    }
  }
  .form {
    margin: 0 auto;

    &__question,
    &__header {
      font-weight: bold;
      padding: $size_14 0 $size_20;

      &__options {
        display: flex;
        justify-content: flex-start;
        min-height: 37px;
        flex-wrap: wrap;

        @media only screen and (max-width: 560px) {
          flex-direction: column;
        }

        &__option {
          padding: 5px 0;
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-right: 50px;

          svg {
            min-width: 32px;
            margin-right: 10px;
          }
          &:nth-child(5) > .option-checkbox {
            align-self: flex-start;
          }

          .other-option {
            margin-top: 10px;
            align-self: flex-start;
            .textbox-container {
              display: block;
            }
            .textbox {
              display: block;
              border: 1px solid #e0e0e0;
              font-family: "Roboto", sans-serif;
              font-size: $size_16;
              font-weight: 400;
              resize: none;
              height: 86px;
              margin: 10px 0px 32px 0px;
              padding: 10px 10px 0px 10px;
              border: 1px solid #e0e0e0;
              outline: none;

              @media only screen and (max-width: $breakpoint_518) {
                width: 100%;
              }
            }
          }
        }
      }
    }

    &-form {
      &.__question {
        margin-bottom: 10px;
      }
    }
  }
  &__form-section {
    display: flex;
    padding: 0px 25px;
    margin: 32px auto;
    justify-content: center;
    padding-bottom: 70px;
    border-bottom: $grey_vlight solid 2px;

    @media only screen and (max-width: $breakpoint_1024) {
      flex-direction: column;
      margin: 20px auto;
      padding: 0px 5px;
    }

    .card-box,
    .notice {
      margin: auto;
      width: 400px;
    }

    .notice {
      color: $grey_dark;
      margin-top: $size_24;
      line-height: 1.7;
      
      @media only screen and (max-width: $breakpoint_518) {
        margin-top: $size_14;
      }
    }

    .card-box {
      padding: 30px;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(5, 10, 34, 0.07);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      order: 1;

      &__header {
        font-weight: bold;
        padding: $size_14 0;
      }

      &__body {
        padding-bottom: $size_32;
      }
      @media only screen and (max-width: $breakpoint_1024) {
        max-width: 600px;
        width: 100%;
        margin: 20px auto;
        order: 2;
      }
    }

    .plans {
      justify-content: space-between;
      margin: 0 auto;

      @media only screen and (max-width: $breakpoint_768) {
        width: 100%;
      }

      .box {
        width: 30%;
        @media only screen and (max-width: $breakpoint_880) {
          width: 45%;
        }
        @media only screen and (max-width: $breakpoint_658) {
          width: 100%;
        }

        img {
          width: 24px;
          height: auto;
        }
      }
    }
  }

  &__required {
    color: #dd4e52;
    vertical-align: super;
    font-size: 14px;
  }
}

.capitalise-first-letter{
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}

.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
  background-color: $grey_mlight;

  &__container {
    text-align: center;
  }
  &__text,
  &__radio {
    display: block;
  }

  &__text {
    text-transform: lowercase;
    cursor: default;
    font-size: $size_14;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__connector {
    color: $grey_light;
    font-size: 20px;
    cursor: default;
    user-select: none;
    padding: 0 20px;

    @media only screen and (max-width: $breakpoint_518) {
      font-size: 10px;
      padding: 0px;
    }
  }
  &__text {
    color: $grey_light;
  }
}

.cancellation-loading-icon {
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;

  @media only screen and (max-width: $breakpoint_1024) {
    height: 40vh;
  }
}
