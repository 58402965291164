.search-and-clear {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;

  .edit-button {
    position: absolute;
    right: 0;
    top: 0;
    color: $grey_light;
    cursor: pointer;
    margin-left: 10px;
  }
}

.button-filter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  .label {
    margin-right: 8px;
  }
}

.button-filter {
  position: relative;
  transition: all 0.2s ease-out;
  &.active {
    background-color: $grey_mlight;
    border: 1px solid transparent;
  }
  .options-container {
    transition: opacity 0.2s ease-out;
    display: inline-block;
    &.hide {
      height: 0;
      opacity: 0;
      width: 0;
      overflow: hidden;
    }
    &.show {
      height: auto;
      border-radius: 5px;
      position: absolute;
      opacity: 1;
      top: 40px;
      right: 0;
      border-radius: 5px;
      box-shadow: 0px 4px 4px rgba(20, 17, 64, 0.2);
      z-index: 1;
      
      .option {
        border-top: 2px solid $white;
        padding: 10px;
        background-color: $grey_mlight;
        min-width: 130px;
      }
      .option:first-child {
        border-top: none;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      .option:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

.button-search,
.button-filter {
  border: 1px solid $black;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.no-border{
    border: none;
  }

  &.disabled {
    pointer-events: none;
    background-color: $grey_vlight;
    border-color: $red;
    opacity: 0.4;
  }
}

.button-search {
  img {
    margin-right: 8px;
  }
}

.clear-history {
  color: $grey_light;
  cursor: pointer;
}
.search-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &__left,
  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 52px;
  }
  &__left {
    width: 100%;
  }
  &__right {
    img {
      cursor: pointer;
    }
  }
  input {
    width: 100%;
    height: 29px;
    border: none;
    font-size: 16px;
    line-height: 28px;
    color: #141140;
    margin: 0;
    padding: 0;
  }
  .icon-close-search {
    margin-left: 40px;
  }
}
.no-result {
  max-width: 600px;
  margin: 60px auto 0 auto;
  text-align: center;
  line-height: 24px;
}
