.MixedContentBox {
  margin-bottom: 80px;
  width: 100%;

  &__Header {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    padding-bottom: 35px;

    @media only screen and (max-width: $breakpoint_1200) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: $breakpoint_768) {
      flex-direction: column;
    }

    a {
      padding: 40px 16px 16px 16px;
      background-color: white;
      margin-bottom: 16px;
      cursor: pointer;
      position: relative;
      color: $black;
      display: block;
      width: 31%;
      &:hover {
        text-decoration: none;
        box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
      }

      .top-label {
        position: absolute;
        height: 24px;
        left: 4.55%;
        right: 80.11%;
        top: 0px;
        border-radius: 0px 0px 4px 4px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        border-radius: 4px;
        text-align: center;
        padding-top: 2px;
        margin-bottom: auto;
        min-width: 54px;

        &.yellow {
          background: #ebae71;
        }

        &.green {
          background: #4ad1b1;
        }

        &.purple {
          background: #ae71eb;
        }

        &.blue {
          background: $blue;
        }

      }

      @media only screen and (max-width: $breakpoint_768) {
        width: 100%;
      }
    }

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin: 16px 0 0 0;

      @media only screen and (max-width: $breakpoint_1200) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__playIcon {
      position: absolute;
      right: 24px;
      top: 182px;

      img {
        width: 48px;
        height: 48px;
      }
    }
  }
}
