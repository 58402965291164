.free-trial-over {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $grey_mlight;
  overflow: auto;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .icon-close {
    position: absolute;
    top: 32px;
    right: 32px;
    display: block;
    cursor: pointer;
    img {
      width: 40px;
      height: 40px;
    }
  }
  &__head {
    font-size: 32px;
  }
  .days {
    min-width: 168px;
    min-height: 168px;
    width: 168px;
    height: 168px;
    border-radius: 50%;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 32px 0;
    font-size: 32px;
    font-weight: bold;
    img {
      margin-bottom: 18px;
    }
  }
  .subheader {
    font-size: 20px;
    line-height: 35px;
    max-width: 352px;
    text-align: center;
    margin-bottom: 28px;
  }
  .boxes {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
  }
  .box {
    border: 2px solid $red;
    width: 250px;
    margin: 0 4px;
    .head {
      background: $red;
      color: $white;
      font-family: 'Playfair Display', serif;
      font-size: 22px;
      line-height: 29px;
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .content-box {
      padding: 16px;
      text-align: center;
      background: $white;
    }
    .price {
      font-size: 24px;
      line-height: 42px;
    }
    .interval {
      font-size: 14px;
      line-height: 20px;
      color: $grey_light;
    }
    .text {
      font-size: 16px;
      line-height: 28px;
      margin-top: 14px;
      margin-bottom: 18px;
      min-height: 84px;
    }
    .btn-upgrade {
      border: 1px solid $red;
      background: $red;
      color: $white;
      font-weight: bold;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      margin: 0 46px;
      cursor: pointer;
    }
    &.brown {
      border-color: $brown;
      .head {
        background-color: $brown;
      }
      .btn-upgrade {
        border-color: $brown;
        background: $white;
        color: $brown;
      }
    }
    &.green {
      border-color: $green;
      .head {
        background-color: $green;
      }
      .btn-upgrade {
        border-color: $green;
        background: $green;
        color: $white;
      }
    }
    &.blue {
      border-color: $blue;
      .head {
        background-color: $blue;
      }
      .btn-upgrade {
        border-color: $blue;
        background: $white;
        color: $blue;
      }
    }
  }
  .link-details {
    color: $red;
    cursor: pointer;
  }
}
// Jazz
.free-trial-over.jazz {
  .link-details {
    color: $blue;
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .free-trial-over {
    &__head {
      font-size: 22px;
    }
    .days {
      min-width: 120px;
      min-height: 120px;
      width: 120px;
      height: 120px;
      font-size: 22px;
      margin: 20px 0;
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .free-trial-over {
    .icon-close {
      top: 8px;
      right: 8px;
    }
    .subheader {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 14px;
    }
    .boxes {
      justify-content: center;
      width: 100%;
    }
    .box {
      width: 46%;
      background: $white;
      .content-box {
        padding: 8px 8px 16px 8px;
      }
      .text {
        font-size: 15px;
      }
      .btn-upgrade {
        margin: 0 8px;
      }
    }
  }
}

@media only screen and (max-height: 670px) {
  .free-trial-over {
    &__container {
      justify-content: flex-start;
      margin-top: 24px;
    }
    .link-details {
      margin-bottom: 24px;
    }
  }
}
