.mc-heart-background {
  border-radius: 50%;
  background: white;
  border: 4px solid rgba(46, 46, 46, 0.247);
  background-clip: padding-box; /* Firefox 4+, Opera, for IE9+, Chrome */
}

.mc-heart-default {
  display: flex;
  align-items: center;
  cursor: pointer;

  .mc-heart-copy {
    color: $red;
  }
}

.mc-heart {
  background-image: url(../../assets/images/heart.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  min-width: 40px;
  cursor: pointer;

  &.transparent {
    background-image: url(../../assets/images/heart_transparent.svg);
  }

  &:hover {
    background-image: url(../../assets/images/heart_hover.svg);
  }

  &.clicked {
    background-image: url(../../assets/images/heart_clicked.svg);
  }

  &.big {
    background-size: 28px 30px;
  }

  &.small {
    background-size: 20px 30px;
  }

  &.dropdown {
    width: 30px;
    height: 30px;
    background-size: 24px;
    margin-left: -10px;
  }

  &.miniplayer {
    width: 40px;
    height: 40px;
    background-size: 30px;
  }
}
