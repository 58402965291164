.subscribe-all {
  padding: 44px 28px 28px 28px;
  max-width: 530px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 30px;
    span {
      color: $red;
      font-weight: bold;
    }
  }
  .box-points {
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
    .points {
      min-width: 43%;
      &:first-child {
        margin-right: 36px;
      }
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        margin: 8px 0;
        img {
          margin-right: 10px;
        }
      }
    }
  }
  .button-red {
    width: 100%;
    max-width: 352px;
    margin-bottom: 24px;
    &:hover {
      text-decoration: none;
    }
  }
  .links {
    span {
      color: $red;
      cursor: pointer;
    }
  }
}
.close-subscribe {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.circle-subscribe {
  border: 2px solid $red;
  box-shadow: 0px 0px 16px rgba(221, 78, 82, 0.2);
  width: 136px;
  height: 136px;
  border-radius: 50%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .price {
    margin-bottom: 5px;
    font-size: 32px;
  }
  .unit {
    font-size: 14px;
    color: $grey_light;
  }
}