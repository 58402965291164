.icon-settings {
  position: relative;
  display: inline-block;
  border-bottom: 1px #3d3c4b;

  &.mobile {
    &.controls {
      img {
        width: 32px;
      }
    }
  }

  .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #3d3c4b;
  }

  .tooltiptext {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 135%;
    width: 120px;
    background-color: #3d3c4b;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    top: -30px;
    left: -40px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.4s;
    pointer-events: none;

    @media only screen and (max-width: $breakpoint_658) {
      display: none;
    }

    &::after {
      content: " ";
      position: absolute;
      top: 100%;
      /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #3d3c4b transparent transparent transparent;
    }
  }

  &:hover .tooltiptext {
    opacity: 1;
    transition-delay: 1s;
  }

  .settings-options-wrapper {
    visibility: hidden;
    background: white;
    position: absolute;
    top: -90px;
    left: -300px;
    width: 372px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #141140;
    box-shadow: 0px 0px 8px rgba(20, 17, 64, 0.5);
    border-radius: 4px;
    cursor: pointer;
    z-index: 1;

    &:after {
      display: block;
      position: absolute;
      right: 60px;
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid $white;
    }

    &.visible {
      visibility: visible;
    }

    .option-wrapper {
      display: flex;
      align-items: center;
      padding: 8px 10px;

      &:hover {
        background: rgba(64, 17, 17, 0.1);
      }

      .icon {
        display: inline;
        margin: 0px 5px;
        opacity: 0;

        &.visible {
          opacity: 1;
        }
      }
    }
  }

  .settings-options-wrapper-mobile {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
    z-index: 1001;
    display: none;

    @media only screen and (max-width: $breakpoint_658) {
      &.visible {
        display: block;
      }
    }

    .icon-close-modal {
      text-align: right;
      padding: 10px;

      img {
        display: inline-block;
      }
    }

    .settings-list-mobile {
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start !important;
        color: $black;
        padding: 15px 16px !important;
        border-bottom: 1px solid $border;

        &:hover {
          background: transparent !important;
        }

        &:first-child {
          border-top: 1px solid $border;
        }

        img {
          margin-right: 10px;
        }

        .icon {
          display: inline;
          padding: 0px 10px 0px 0px;
          opacity: 0;

          &.visible {
            opacity: 1;
          }
        }
      }
    }
  }
}