.interactive-play-icon,
.playIcon,
.interactive-play-icon-album {
  width: 48px;
  height: 48px;
  cursor: pointer;

  &.initialising {
    animation: darken 2s ease-in-out infinite;
    opacity: 1;
  }
  &.show {
    opacity: 1;
  }

  @extend .play-icon-extend;
  img {
    width: 48px;
    height: 48px;
  }
  @media only screen and (max-width: $breakpoint_1024) {
    opacity: 1;
  }
}

//default interactive play icon containers
.DailyPlaylists__Container > a,
.media-card,
.MixedContentBox__Container > a,
.radio-container,
.searchResults.container > a,
.userPlaylistsHome__Container > a,
.list-recommend > li,
.person-page .contents-wrapper,
.contents-inner-wrapper,
.album-search-result-box,
.composers-list li,
.artist-box,
.feature-composers a,
.works-list li,
.welcome-banner .gradient-container-card,
.NewsTile,
.also-like__boxes > a {
  &:hover {
    .interactive-play-icon,
    .interactive-play-icon-album {
      opacity: 1;
      background-color: transparent;
      img {
        background-color: transparent;
      }
    }
  }
}

// interactive play icon album containers
.albums-list .album-component,
.featured-albums .album-component,
.albums-tab > ul > a,
.album-search-result-box,
.contents-wrapper.album {
  &:hover {
    .interactive-play-icon-album {
      width: 48px;
      height: 48px;
      opacity: 1;
      background-color: transparent;
      img {
        background-color: transparent;
      }
    }
  }
}

//misc
.modal-icon-close {
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.force-play-icon-size {
  height: 48px !important;
  width: 48px !important;
  min-width: unset !important;
  min-height: unset !important;
  img {
    height: 48px !important;
    width: 48px !important;
    min-width: unset !important;
    min-height: unset !important;
  }
}

//end misc

.interactive-play-icon-album {
  position: absolute;
}

//reusable components

.default-stack-container {
  .interactive-play-icon-wrapper {
    position: relative;
    .interactive-play-icon-album,
    .interactive-play-icon {
      bottom: unset;
      right: unset;
    }
  }
}
.interactive-play-icon-wrapper {
  position: relative;
  .interactive-play-icon-album,
  .interactive-play-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

//appears on box positioning
.contents-wrapper.album {
  .interactive-play-icon-album {
    @extend .force-play-icon-size;
    top: 85px;
    left: 85px;

    @media only screen and (max-width: $breakpoint_1200) {
      top: 70px;
      left: 70px;
    }

    @media only screen and (max-width: $breakpoint_658) {
      top: 60px;
      left: 60px;
    }
  }
}

//person page  albums tab positioning
.albums-tab > ul > a {
  .interactive-play-icon-album {
    align-self: center;
    justify-self: center;
    margin-top: -80px;
  }
}

//person page for added specificity
.personImage-wrapper {
  position: relative;
  display: flex;
  float: left;
  .interactive-play-icon {
    position: absolute;
    align-self: center;
    opacity: 1;
    right: 0;
    bottom: 0;
    margin-right: -5px;
    margin-bottom: 0;
    @media only screen and (max-width: $breakpoint_880) {
      margin-right: -24px;
      margin-bottom: unset;
      bottom: unset;
    }
  }
}
.person-page {
  .AppearsOnBox .slider-component .contents-wrapper {
    .interactive-play-icon,
    .interactive-play-icon-album {
      img {
        height: 48px;
        width: 48px;
      }
      &.loading,
      &.playing,
      &.loading img,
      &.playing img {
        height: 48px;
        width: 48px;
      }
    }
  }
}

//search page album play icon
.album-search-result-box {
  .interactive-play-icon,
  .interactive-play-icon-album {
    @extend .force-play-icon-size;
    align-self: center;
    justify-self: center;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
}

.feature-composer-info__photo {
  .interactive-play-icon,
  .interactive-play-icon-album {
    @extend .force-play-icon-size;
    position: absolute;
    left: 12px;
    top: 12px;
    img {
      border-color: transparent !important;
    }
  }
}
.feature-composer-info__photo,
.artist-box__image-container {
  position: relative;
  .interactive-play-icon,
  .interactive-play-icon-album {
    @extend .force-play-icon-size;
    position: absolute;
    img {
      border-color: transparent !important;
    }
    @media only screen and (max-width: $breakpoint_1024) {
      left: -10px;
      position: absolute;
      margin-top: 45px;
    }
  }
}
.default-media-card-container.default-artist-box-container {
  .artist-box {
    .interactive-play-icon,
    .interactive-play-icon-album {
      @extend .force-play-icon-size;
      position: absolute;
      img {
        border-color: transparent !important;
      }
      @media only screen and (max-width: $breakpoint_1024) {
        position: absolute;
        right: 10px;
        bottom: 0;
        margin-top: unset;
        left: unset;
      }
      @media only screen and (max-width: $breakpoint_518) {
        right: -10px;
      }
    }
  }
}

.composers__left {
  position: relative;
  .interactive-play-icon,
  .interactive-play-icon-album {
    @extend .force-play-icon-size;
    left: 9px;
    position: absolute;
    img {
      border-color: transparent !important;
    }
    @media only screen and (max-width: $breakpoint_1024) {
      left: -10px;
      position: absolute;
      margin-top: 45px;
    }
  }
}

.works-list li div.interactive-play-icon {
  position: relative;
}
