.forgot-password-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.container-forgot {
  background: $grey_mlight;
  padding: 40px;
  min-height: 100%;
  display: flex;
  flex: 1;
  &__content {
    background: $white;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    .confirm {
      display: flex;
      flex-direction: column;
      align-items: center;
      &.jazz {
        .link-login {
          span {
            color: $blue;
          }
        }
      }
    }
  }
  .header-text {
    font-size: 32px;
    line-height: 45px;
    text-align: center;
  }
  .subheader {
    line-height: 24px;
    max-width: 352px;
    text-align: center;
    margin-top: 24px;
  }
  .link-signup {
    padding-top: 5px;
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    width: 100%;
    max-width: 352px;
    padding: 10px 12px;
    margin-top: 24px;
    margin-bottom: 8px;
    &:focus {
      padding: 10px 12px;
    }
  }
  .btn-submit {
    background: $red;
    width: 100%;
    max-width: 352px;
    min-width: 150px;
    height: 48px;
    color: $white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin-top: 24px;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
    &.jazz {
      background: $blue;
    }
  }
  .box-text {
    margin-top: 24px;
    .text {
      line-height: 24px;
    }
  }
  .error {
    text-align: left;
    width: 100%;
    max-width: 352px;
  }
  .link-login {
    padding-top: 8px;
    span {
      color: $red;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .container-forgot {
    padding: 20px;
    .header-text {
      font-size: 26px;
      line-height: 38px;
    }
  }
}
