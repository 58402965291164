.mobilemenu {
  width: 100%;
  height: 66px;
  padding-top: 8px;
  background-color: white;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  z-index: 11;
  border-top: 1px;
  border-color: #dcdfe4;
  border-style: solid;
  display: none;
  outline: 0;

  a {
    text-decoration: none;
  }

  &__item__container {
    flex-direction: column;
    height: 100%;
    width: 100%;
    cursor: pointer;

    img {
      padding-top: 10px;
      margin: auto;
    }

    div {
      margin: auto;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      color: #141140;
    }
  }

  @media only screen and (max-width: $breakpoint_1200) {
    display: flex;
  }
}

.expanded-browse {
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 0px 8px rgba(20, 17, 64, 0.3);
  max-height: 0px;
  transition: max-height 0.2s;

  .menu-item {
    padding: 20px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    color: inherit;
    outline: 0;

    &.active {
      color: $red;
      a {
        color: $red;
      }
      &.jazz {
        color: $blue;
        a {
          color: $blue;
        }
      }
    }
  }

  &.show {
    max-height: 500px;
  }
}
