@import "./constants.scss";

.card {
  &__image {
    margin-bottom: 16px;
  }

  &__title {
    line-height: 28px;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  &__desc {
    line-height: 28px;
    font-size: 16px;
    margin-bottom: 15px;
    min-height: 84px;
  }

  .link {
    font-weight: bold;
    line-height: 23px;
    font-size: 16px;
    color: $red;
  }
}

.media-card {
  cursor: pointer;
  position: relative;
  color: $black;
  display: inline-block;
  background-color: $white;
  border-radius: $card_border_radius;
  padding: 22px;
  height: max-content;
  width: 100%;
  max-width: 300px;
  min-height: 100%;

  &:not(.focal) {
    @extend .bordered;
    @extend .hover-shadow;
    @extend .hover-darken;
  }

  &:hover {
    .images_container {
      .children-1 {
        &.default {
          img {
            scale: 1.2;
          }
        }
      }
    }
  }

  .label {
    border-radius: 0px 0px 4px 4px;
    background: $brown;
    position: absolute;
    top: 0;
    left: 30px;
    padding: 5px 8px;
    font-size: 14px;
    line-height: 20px;
    color: $white;
    z-index: 1;

    &.blue {
      background-color: $blue;
    }

    &.green {
      background: #4ad1b1;
    }

    &.purple {
      background: #ae71eb;
    }
  }

  .star-container {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
  }

  .star-container {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
  }

  .mc-heart {
    margin-left: auto;
  }

  &:last-child {
    margin-right: 0;
  }

  .card-icon {
    width: 25px;
    height: 25px;
    position: absolute;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title,
  .description {
    display: flex;
    line-height: 1.1;

    .description-text {
      &-mobile {
        @media only screen and (max-width: $breakpoint_658) {
          display: -webkit-box;
        }
      }

      display: -webkit-box;
    }
  }

  .content-type-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #8f99aa;
    padding-top: 5px;
  }

  .icon-remove {
    position: absolute;
    right: -15px;
    z-index: 3;
    top: -15px;
  }

  .mobile-media-parent {
    align-items: center;

    .images_container_mobile {
      max-width: 30%;
      max-height: 200px;
      height: 80px;
      width: 80px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
    }

    .text-container {
      margin-left: 15px;
      max-width: 70%;
    }
  }

  .images_container {
    max-width: 100%;
    max-height: 200px;
    height: 200px;
    overflow: hidden;
    margin-bottom: 16px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .play-video {
      position: absolute;
    }

    @media only screen and (max-width: $breakpoint_518) {
      height: 100px;
    }

    @media only screen and (max-width: $breakpoint_768) {
      max-width: unset;
      width: 100%;

      .children-2,
      .children-3 {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        img {
          width: auto;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .children-1 {
      position: relative;
      width: 100%;
      height: 100%;

      &.default {
        -webkit-transform: translate3d(0, 0, 0) img {
          -webkit-transform: translate3d(0, 0, 0);
          height: auto;
          min-height: 100%;
          width: 100%;
          object-fit: cover;
          -webkit-transition: 300ms;
          transition: scale 300ms;
          -webkit-backface-visibility: hidden;
        }
      }

      img {
        margin: 0 auto;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .children-2 {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;

      img {
        height: 95%;
        width: auto;
        box-shadow: 0 5px 5px rgba($black, 0.4);
      }

      .image-2 {
        position: absolute;
        left: 15px;
        bottom: -10px;
      }

      @media only screen and (max-width: $breakpoint_768) {
        justify-content: space-evenly;

        img,
        .image-2 {
          position: relative;
          width: 48%;
          height: auto;
          box-shadow: none;
          margin-right: 2%;
          object-fit: cover;
          object-position: top;
        }
      }
    }

    .children-3 {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        width: 85%;
        height: auto;
        box-shadow: 0 5px 5px rgba($black, 0.4);
      }

      .image-2 {
        position: absolute;
        left: 10%;
        top: 10%;
      }

      .image-3 {
        position: absolute;
        left: 20%;
        top: 30%;
      }

      @media only screen and (max-width: $breakpoint_768) {
        justify-content: space-evenly;

        img,
        .image-2,
        .image-3 {
          position: relative;
          width: 32%;
          height: auto;
          box-shadow: none;
          margin-right: 2%;
          object-fit: cover;
          object-position: top;
          right: unset;
          top: unset;
          left: unset;
        }
      }
    }

    .children-4 {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        height: 50%;
        width: 49%;
        margin: 0 1% 1% 0;
        float: left;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &.video-card {
    .play-video {
      img {
        content: url("../assets/images/video_play_button.svg");
      }
    }

    &:hover {
      .play-video {
        img {
          content: url("../assets/images/video_play_button_hover.svg");
        }
      }
    }

    &.jazz {
      &:hover {
        .play-video {
          img {
            content: url("../assets/images/video_play_button_hover_blue.svg");
          }
        }
      }
    }
  }

  &.mixed-card {
    width: 250px;
    margin: 0px auto;
  }

  &.create-playlist-button {
    .images_container {
      img {
        opacity: 0.5;
      }
    }
  }
}