.my-playlists {
  .loading-listening {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .header-playlists {
    padding: 32px 0;
    background: $black;
    color: $white;
  }
  .header-text {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    margin-bottom: 20px;
  }
  .bar-create {
    position: relative;
  }
  .button-create {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $red;
    cursor: pointer;
    img {
      margin-right: 10px;
    }
  }
  .input-create-playlist {
    display: flex;
    flex-direction: row;
    align-items: center;
    input {
      margin: 0;
      height: 40px;
      padding: 0 10px;
      font-size: 14px;
      width: 100%;
      &:focus {
        padding: 0 10px;
      }
    }
    .button-add-playlist,
    .button-close-playlist {
      cursor: pointer;
      margin-left: 10px;
      border: 1px solid $black;
      color: $black;
      height: 40px;
      padding: 0 10px;
      display: flex;
      align-items: center;
    }
    .button-close-playlist {
      margin-left: 10px;
      border-color: $red;
      color: $red;
    }
  }
  .edit-button {
    position: relative;
    color: $grey_light;
    cursor: pointer;
    margin-left: 10px;
    font-size: $size_16;
    font-weight: normal;
  }
  .list-your-playlist {
    padding: $size_20 0;
    a {
      text-decoration: none;
      color: $black;
      &:visited {
        text-decoration: none;
        color: $black;
      }
    }
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 24px;
    }
    &__left {
      text-decoration: none;
      color: inherit;
      input {
        margin: 0;
        padding: 0 10px;
        height: 48px;
        width: 100%;
        &:focus {
          padding: 0 10px;
        }
      }
    }
    &__left,
    &__right {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    &__right {
      margin-left: 10px;
      .arrow-right {
        position: relative;
        left: 3px;
      }
      .icon-remove {
        position: relative;
        left: -3px;
        cursor: pointer;
      }
    }
    .icon-playlist {
      min-width: 48px;
      min-height: 48px;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $grey_vlight;
      margin-right: 15px;

      &.user {
        img {
          width: 24px;
          height: 24px;
        }
      }

      &.vialma {
        img {
          width: 48px;
          height: 48px;
          object-fit: cover;
        }
      }
    }

    input {
      height: 35px;
      border: 1px solid $border;
      padding: 16px 4px;
      margin-bottom: 16px;
      font-size: 16px;
      color: $black;
      border-radius: 5px;
      outline: none;
      width: 75%;
      ::placeholder {
        color: $border;
      }
      &:focus,
      &.filled {
        padding-top: 16px;
      }
    }
  }
  .button-save-edit {
    cursor: pointer;
    color: $white;
    background-color: $black;
    height: 35px;
    padding: 16px 4px;
    font-weight: bold;
    font-size: $size_16;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    border-radius: 5px;
  }

  .tabContainer {
    margin-top: 40px;
    padding-bottom: 64px;
    display: none;
    &.visible {
      display: block;

      .no-favourite-container {
        display: flex;
        flex-direction: row;

        span {
          margin: 5px;
        }

        img {
          margin-left: 25px;
        }
      }
    }
  }

  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: inset 0px -1px 0px #dcdfe4;
    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 72px;
      margin: 0 32px;
      color: $grey_light;
      font-family: "Playfair Display", serif;
      font-weight: 600;
      font-style: normal;
      font-size: 22px;
      position: relative;
      cursor: pointer;
      &.active {
        color: $black;
        &:before {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          height: 3px;
          width: 100%;
          background: $red;
        }
      }
    }
  }

  .media-card.create-playlist-button {
    @media only screen and (max-width: $breakpoint_518) {
      .images_container {
        .default {
          position: relative;
        }
      }
      .title,
      .images_container {
        margin-bottom: 3px;
      }
    }
  }

  .no-result {
    .mc-heart.inline-heart {
      display: inline-block;
      line-height: 40px;
    }
  }
}

// Jazz
.my-playlists.jazz {
  .button-create {
    color: $blue;
  }
  .input-create-playlist {
    .button-close-playlist {
      color: $blue;
      border-color: $blue;
    }
  }
}

.create-playlist-modal {
  margin: auto;
  max-width: 640px;
  .modal-close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  &.rename {
    // display: flex;
    // flex-direction: column;
    .header {
      margin-bottom: 10px;
      .playlist-list-image-container {
        width: 275px;
        height: 250px;
        position: relative;
        &.children-1 {
          overflow: hidden;
          img {
            height: auto;
            width: 100%;
            margin: 0 auto;
          }
        }
        &.children-2 {
          .playlist-list-image-2 {
            @media only screen and (max-width: $breakpoint_880) {
              position: relative;
            }
          }
          img {
            width: 70%;
          }
        }
        &.children-2 {
          .playlist-list-image-2 {
            @media only screen and (max-width: $breakpoint_880) {
              position: relative;
            }
          }
          img {
            width: 70%;
          }
        }
        &.children-3 {
          .playlist-list-image-3 {
            left: 20%;
          }
          img {
            width: 50%;
          }
        }
        &.children-4 {
          margin: 0 auto;
          img {
            position: relative;
            max-width: 45% !important;
          }
        }
      }
    }
  }
  .header {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
  }

  .content-modal {
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    input:focus,
    input.filled {
      padding-top: 16px;
    }
    .btn-blue,
    .btn-red {
      width: 264px;
      height: 48px;
      border-radius: 100px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-weight: bold;
      margin-bottom: 24px;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
      }
    }

    .btn-blue {
      background: $blue;
    }
    .btn-red {
      background: $red;
    }
  }
}
