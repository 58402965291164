.generic-page-header {
  position: relative;
  @media only screen and (max-width: $breakpoint_658) {
    margin-bottom: 16px;
  }
  .text-header {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 42px;
    color: $black;
    margin-bottom: 24px;
  }
  .subheader {
    font-size: 16px;
    line-height: 28px;
    color: $black;
    max-width: 350px;
    @media only screen and (max-width: $breakpoint_880) {
      margin: 0 auto;
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    max-width: 1120px;
    margin: 0px auto;
    width: 100%;
    @media only screen and (max-width: $breakpoint_880) {
      flex-direction: column;
    }
  }
  .left-section,
  .right-section {
    padding: 63px 0px 80px 0px;
    min-width: 50%;
    @media only screen and (max-width: $breakpoint_880) {
      margin: 0px 0px;
      padding: 0px;
    }
  }
  .left-section {
    align-self: center;
    position: relative;
    z-index: 2;
    @media only screen and (max-width: $breakpoint_880) {
      padding-top: 15px;
      padding-bottom: 15px;
      width: 100%;
      text-align: center;
    }
  }
}

.jazz {
  .gradient-container {
    .gradient {
      background: linear-gradient(101.44deg, $white 5%, $blue 100%);
    }
  }
}

.gradient-container {
  .gradient {
    position: absolute;
    right: 0;
    width: 54.5%;
    height: 180px;
    top: 50%;
    margin-top: -100px;
    background: linear-gradient(101.44deg, $white 5%, $red 100%);
  }

  //for home page
  &.full-width {
    position: relative;
    .gradient {
      width: 100%;
      top: unset;
      margin-top: 10px;
    }

    .gradient-container-card {
      margin: 0 auto;
    }
  }

  &-card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 210px;
    padding: 10px;
    background: $white;
    border-radius: $card_border_radius;
    width: 80%;
    max-width: 400px;
    margin-left: auto;
    position: relative;
    opacity: 1;
    animation: fadeIn 1s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    @media only screen and (max-width: $breakpoint_658) {
      width: 90%;
      max-width: unset;
    }

    &.horizontal {
      &.padded-20 {
        padding: 20px;
      }

      flex-direction: row;
      .card-left,
      .card-right {
        flex: 1;
        align-self: start;
        text-align: left;
        position: relative;
        &.picture-wrapper {
          align-self: stretch;
          .picture {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
          .interactive-play-icon {
            position: absolute;
            right: 0;
            bottom: 0;
            @media only screen and (max-width: $breakpoint_768) {
              display: block;
            }
          }
        }

        text-align: left;
        .subtitle {
          line-height: 20px;
        }
        .title {
          font-weight: bold;
          margin-bottom: 20px;
        }
      }
      .card-right {
        padding-left: 20px;
      }
    }
    @extend .shadow;
    @media only screen and (max-width: $breakpoint_880) {
      margin: 0 auto;
    }
    .card-top-icon {
      position: absolute;
      top: -24px;
      width: 50px;
      height: auto;
    }

    .card-text {
      margin-top: 30px;
      padding: 0px 24px;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 28px;
    }
  }

  @media only screen and (max-width: $breakpoint_880) {
    &.small {
      .gradient {
        top: unset;
        bottom: 5px;
      }
    }
    .gradient {
      width: 100%;
      height: 190px;
      top: unset;

      bottom: 10px;
      &.no-right-content {
        display: none;
      }
    }
  }
}

.read-more-gradient-align {
  > img {
    display: flex;
    align-self: flex-start;
  }
  &::before {
    position: absolute;
    margin-top: 5%;
    right: 0;
    width: 54.5%;
    height: 240px;
    background: linear-gradient(101.44deg, #141140 38.41%, #dd4e52 100%);
    content: " ";
    display: flex;
    align-self: flex-start;
    @media only screen and (max-width: $breakpoint_768) {
      height: 120px;
      left: 0;
      width: unset;
    }
    @media only screen and (max-width: $breakpoint_658) {
      margin-top: 6%;
    }
  }
  .jazz & {
    &::before {
      background: linear-gradient(99.65deg, #141140 38.41%, #4866e0 91.66%);
    }
  }
}
