@import "./constants.scss";

.default-artist-box-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px 3%;
  overflow: hidden;
  transition: all 1.5s cubic-bezier(0, 1, 0, 1);

  &.default-media-card-container {
    overflow: unset;
    .artist-box {
      height: 100%;
      margin-top: 0;
      margin-bottom: 0;
      .mc-heart-default {
        display: none;
      }
      .artist-box__image-container {
        padding-left: unset;
        padding-right: unset;
        width: 200px;
        height: 200px;
        @media only screen and (max-width: $breakpoint_518) {
          width: 100px;
          height: 100px;
        }
      }
    }

    .artist-box__image-container {
      height: unset;
      width: unset;
      margin-left: auto;
      margin-right: auto;
      margin-top: unset;
      margin-bottom: unset;
      padding-left: 22px;
      padding-right: 22px;
      max-height: 200px;
      width: 100%;
      @media only screen and (max-width: $breakpoint_658) {
        width: unset;
      }
    }
    .artist-box__name {
      text-align: left;
      padding: 22px;
      display: flex;
      .mc-heart-default {
        display: block;
        margin-left: auto;
        position: relative;
        right: unset;
        top: unset;
      }
    }
    .artist-box__details,
    .artist-box__quote {
      display: none;
    }
  }

  @media only screen and (max-width: $breakpoint_880) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  &.composers-to-discover {
    @media only screen and (max-width: $breakpoint_518) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
  &.four-per-row {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 10px 3%;
    @media only screen and (max-width: $breakpoint_1024) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media only screen and (max-width: $breakpoint_880) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    &.composers-to-discover {
      @media only screen and (max-width: $breakpoint_518) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }

  &.mini {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    .artist-box {
      width: 200px;

      @media only screen and (max-width: $breakpoint_658) {
        width: 100%;
      }
    }
  }

  &.transparent-boxes {
    .artist-box {
      background-color: $white;
      &:hover {
        @extend .hover-darken;
      }
    }
  }

  .artist-box {
    background-color: $grey_mlight;
    width: 100%;
    border-radius: 4px;
    margin: 20px auto;
    padding: 22px 0px;
    text-decoration: none;
    position: relative;
    display: flex;
    flex-direction: column;
    @extend .bordered;
    @extend .hover-shadow;
    @extend .hover-darken;
    &.jazz {
      &:hover {
        .artist-box__image-container {
          > img {
            border-color: $blue;
          }
        }
      }
    }
    &:hover {
      .artist-box__image-container {
        > img {
          border-color: $red;
        }
      }
    }

    & > span.label {
      position: absolute;
      z-index: 1;
      margin-left: 5px;
      margin-top: -23px;
      padding: 4px;
      background: $brown;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      color: $white;
      text-transform: capitalize;
    }
    .mc-heart-default {
      position: absolute;
      right: 0px;
      top: 0px;
    }

    &__image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        border: 3px solid transparent;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
      width: 66px;
      height: 66px;
      border-radius: 50%;
      margin: 10px auto 20px auto;
      position: relative;
      object-fit: cover;
      object-position: top;

      @media only screen and (max-width: 435px) {
        width: 80px;
        height: 80px;
      }
    }
    &__details-container {
      text-align: center;
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      line-height: 22px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .artist-quote-container {
        padding: 10px;
        margin-top: 15px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .quote-icon {
          height: 16px;
          top: -16px;
          position: relative;
        }
      }
    }

    &__name {
      font-weight: 700;
      padding: 0px 5px 7px;
      color: #141140;
      .mc-heart-default {
        display: none;
      }
    }
    .button-red-outlined,
    .button-blue-outlined {
      display: block;
      min-width: 80%;
      margin: 0 auto;
      width: auto;
    }
    &__details {
      font-weight: 400;
      padding: 0 5px;
      &.truncate {
        display: -webkit-box;
      }
    }
  }
  &.default-stack-container {
    &.editors-picks {
      @media only screen and (max-width: $breakpoint_658) {
        .artist-box {
          span.label {
            margin-left: -10px;
            margin-top: -15px;
          }
          flex-direction: column;
          &__image-container {
            margin-left: auto;
            margin-right: auto;
          }
          &__details-container {
            text-align: center;
          }
        }
      }
    }
    @media only screen and (max-width: $breakpoint_658) {
      .artist-box {
        display: flex;
        flex-direction: row;
        padding: 16px;
        width: 100%;
        margin: 0;
        margin-bottom: 10px;

        &__image-container {
          max-width: 66px;
          width: 100%;
          height: 66px;
          margin: 0;
          margin-right: 10px;
        }
        &__details-container {
          text-align: left;
        }
      }
    }
  }
}
