.listening-history {
  .header-listening {
    padding: 32px 0;
    background: $black;
    color: $white;
  }
  .header-text {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-style: normal;
    font-size: 32px;
    margin-bottom: 20px;
  }
  .loading-listening {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .search-and-clear {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }
  .listening-list {
    margin-top: 28px;
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      padding: 16px;
      .icon-play-circle-red {
        display: none;
      }
      &:hover {
        background: rgba(221, 78, 82, 0.1);
        border-radius: 4px;
        .composer-img {
          display: none;
        }
        .icon-play-circle-red {
          display: block;
        }
      }
      &.active {
        .composer-img {
          display: block;
        }
        .icon-play-circle-red {
          display: none;
        }
      }
      &.disabled {
        opacity: 0.5;
        .icon-more {
          display: none;
        }
        &:hover {
          background: none;
          .composer-img {
            display: block;
          }
          .icon-play-circle-red {
            display: none;
          }
        }
      }
      .active {
        color: $red;
      }
    }
    &__left {
      cursor: pointer;
    }
    &__left,
    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__right {
      padding-left: 30px;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
    .image-composer {
      margin-right: 12px;
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
    }
    .composer {
      font-weight: bold;
      margin-right: 8px;
    }
    .performances {
      color: $grey_light;
      margin-top: 8px;
    }
  }
  .button-search {
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      margin-right: 8px;
    }
  }
  .clear-history {
    color: $grey_light;
    cursor: pointer;
  }
  .search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__left,
    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 52px;
    }
    &__left {
      width: 100%;
    }
    &__right {
      img {
        cursor: pointer;
      }
    }
    input {
      width: 100%;
      height: 29px;
      border: none;
      font-size: 16px;
      line-height: 28px;
      color: #141140;
      margin: 0;
      padding: 0;
    }
    .icon-close-search {
      margin-left: 40px;
    }
  }
  .no-result {
    max-width: 600px;
    margin: 60px auto 0 auto;
    text-align: center;
    line-height: 24px;
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .listening-history {
    .image-composer {
      display: none;
    }
    .listening-list {
      li {
        padding-left: 0;
        padding-right: 0;
        &:hover {
          background: transparent;
        }
        .performances {
          display: none;
        }
      }
    }
  }
}
