.AppearsOnBox {
  @media only screen and (max-width: $breakpoint_1200) {
    padding: 0px 16px;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    padding: 10px 0;
  }

  .slick-slide {
    display: flex;
    align-self: stretch;
    height: unset;
    > div {
      display: flex;
      align-self: stretch;
      width: 100%;
    }

    .contents-wrapper {
      height: 100%;
    }
  }

  &__Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 0px;

    &.with-divider {
      border-top-style: solid;
      border-width: 1px;
    }

    &.no-divider {
      border-top-style: none;
      border-width: 0px;
    }

    &__Title {
      font-family: "Playfair Display", serif;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      padding-bottom: 35px;

      @media only screen and (max-width: $breakpoint_1200) {
        font-size: 24px;
        line-height: 29px;
      }
    }

    &__ArrowWrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      &.album,
      &.featuredAlbum,
      &.podcast,
      &.chapter,
      &.article {
        &.threeItem {
          @media only screen and (min-width: $breakpoint_880) {
            display: none;
          }
        }
        &.twoItem {
          @media only screen and (min-width: 560px) {
            display: none;
          }
        }
        &.oneItem {
          @media only screen and (min-width: $breakpoint_min) {
            display: none;
          }
        }
      }
      &.album,
      &.featuredAlbum,
      &.playlist,
      &.video {
        &.fourItem {
          @media only screen and (min-width: $breakpoint_1200) {
            display: none;
          }
        }
        &.threeItem {
          @media only screen and (min-width: $breakpoint_768) {
            display: none;
          }
        }
        &.twoItem {
          @media only screen and (min-width: $breakpoint_518) {
            display: none;
          }
        }
        &.oneItem {
          @media only screen and (min-width: $breakpoint_min) {
            display: none;
          }
        }
      }

      img {
        padding-left: 30px;
        cursor: pointer;
      }
    }
  }

  .slider-component {
    font-family: "Roboto", sans-serif;
    font-style: normal;

    .contents-outer-wrapper {
      width: 100%;
      height: 100%;

      .contents-inner-wrapper {
        border: 1px solid #dcdfe4;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0px 14px;
        background: white;
        padding: 22px;
        text-decoration: none;
        margin-bottom: 8px;
        color: #141140;
        height: 100%;
        transition: all 0.2s;

        &:hover {
          background: #f4f4f4;
          box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
          border-color: transparent;

          .appears-on-playIcon {
            &.video {
              img {
                content: url("../../assets/images/video_play_button_hover.svg");
              }
            }
          }
        }

        .image-wrapper {
          position: relative;

          .appears-on-star {
            .star {
              width: 25px;
              height: 25px;
              position: absolute;
              top: -5px;
              left: 184px;

              @media only screen and (max-width: $breakpoint_1200) {
                left: 165px;
              }

              @media only screen and (max-width: $breakpoint_658) {
                left: 125px;
              }
            }
          }

          img {
            width: 208px;
            height: 208px;
            object-fit: cover;
            border-radius: 5px;

            &.rounder-border {
              border-radius: 20px;
            }
          }

          .appears-on-playIcon {
            position: absolute;
            z-index: 2;
            right: 15px;
            bottom: 15px;

            &.video {
              right: 38%;
              top: 38%;
            }

            img {
              width: 48px;
              height: 48px;
            }
          }
        }

        .description-wrapper {
          align-self: start;
          padding-top: 17px;
          width: 208px;
          align-self: center;

          .title {
            font-size: 16px;
            font-weight: 700;
            line-height: 1.3;
          }

          .description {
            padding-top: 20px;
            font-size: 16px;
            font-weight: 400;
            padding-top: 20px;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
