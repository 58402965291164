.change-password {
  .form {
    background: $grey_mlight;
    padding: 24px;
  }
  .head-form {
    color: $grey_light;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .row-form {
    &:last-child {
      input {
        margin-bottom: 0;
      }
    }
    .label {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    input {
      padding: 10px;
      height: 48px;
      min-width: 280px;
      &:focus {
        padding: 10px;
      }
    }
  }
  .row-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
  }
  .error {
    font-size: 14px;
  }
  .cancel {
    line-height: 23px;
    font-weight: bold;
    color: $grey_light;
    cursor: pointer;
  }
  .save {
    background: $red;
    padding: 0 20px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 23px;
    border-radius: 100px;
    font-weight: bold;
    color: $white;
    cursor: pointer;
  }
}