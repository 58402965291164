.ErrorPage {
  padding-top: 50px;

  @media only screen and (max-width: $breakpoint_768) {
    padding-top: 0px;
  }

  &__TopSection {
    &__ImageContainer {
      display: flex;
      justify-content: center;
    }
    &__Heading {
      margin: 70px 0px 60px 0px;
      font-family: 'Playfair Display', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 42px;
      line-height: 58px;
      text-align: center;
      letter-spacing: 0.01em;

      @media only screen and (max-width: $breakpoint_768) {
        margin: 0px 0px 60px 0px;
      }
    }

    &__Button {
      display: block;
      max-width: 296px;
      width: 100%;
      margin: 0 auto;
    }
  }

  &__ContactSection {
    margin-top: 115px;

    @media only screen and (max-width: $breakpoint_768) {
      margin-top: 60px;
    }

    &__Copy {
      max-width: 375px;
      margin: 0 auto;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 42px;

      @media only screen and (max-width: $breakpoint_768) {
        padding: 0 20px;
      }
    }


    &__ContactInfo {
      margin: 50px 0px;
      & p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;

        & svg {
          margin-right: 5px;
        }
      }
    }
  }
}