.icon-more {
  position: relative;
  cursor: pointer;

  .more-menu {
    position: absolute;
    z-index: 20;
    right: 54px;
    bottom: -23px;
    background: $white;
    box-shadow: 0px 0px 8px rgba(20, 17, 64, 0.5);
    border-radius: 4px;
    color: $black;
    min-width: 200px;
    transition: all 200ms;

    &.top-adjustment {
      bottom: unset;
      top: 0;

      li ul {
        top: 0px;
        bottom: auto;
      }

      &:after {
        transform: translate(8px, -10px) rotate(270deg);
        -webkit-transform: translate(8px, -10px) rotate(270deg);
        top: 27px;
        bottom: -30px;
        position: absolute;
        background: transparent;
      }
    }

    &.bottom-adjustment {
      top: unset;
      bottom: -17px;

      li ul {
        top: 0px;
        bottom: auto;
      }

      &:after {
        transform: translate(8px, -10px) rotate(270deg);
        -webkit-transform: translate(8px, -10px) rotate(270deg);
        top: 210px;
        bottom: 0px;
        position: absolute;
        background: transparent;
      }
    }

    &:after {
      display: block;
      position: absolute;
      right: 1px;
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid $white;
      transform: translate(8px, -45px) rotate(270deg);
    }

    >li,
    >a {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 7px 13px;
      margin: 0;
      cursor: pointer;
      text-decoration: none;
      color: inherit;

      &.create-playlist {
        align-items: unset;
        padding: 0;
      }

      &:hover {
        background: rgba(20, 17, 64, 0.1);
      }

      &:first-child {
        border-radius: 4px 4px 0 0;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;
      }

      img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
      }

      ul {
        position: absolute;
        right: 100%;
        bottom: 0px;
        background: $white;
        box-shadow: 0px 0px 8px rgba(20, 17, 64, 0.5);
        border-radius: 4px;
        display: none;

        li {
          white-space: nowrap;
          padding: 10px;
        }
      }

      .box-list {
        position: static;
        width: 100%;
        overflow: auto;

        ul {
          position: static;
          max-height: 200px;
          overflow-y: auto;
          max-width: 300px;
        }
      }

      &.add-to-playlist {
        position: relative;

        &:hover {
          ul {
            display: block;
          }
        }
      }
    }
  }
}

.container-webplayer,
.add-modal {
  .box-list {
    position: static;
    width: 100%;
    overflow: auto;

    ul {
      position: static;

      &.with-images {
        .image-container {
          width: 50px;
          height: 50px;
          border-radius: 5px;
          margin-right: 10px;
          overflow: hidden;

          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }

  .add-to-playlist {
    position: relative;

    >ul {
      position: absolute;
      right: 100%;
      bottom: 0px;
      background: $white;
      box-shadow: 0px 0px 8px rgba(20, 17, 64, 0.5);
      border-radius: 4px;
      display: none;
      z-index: 1;

      li {
        white-space: nowrap;
        padding: 10px;

        &.create-playlist {
          padding: 0;
          display: flex;
          flex-direction: column;
          margin: 0;
        }
      }
    }

    &:hover {
      ul {
        display: block;

        @media only screen and (max-width: $breakpoint_880) {
          display: none;
        }
      }
    }
  }
}

.add-modal {
  .create-playlist {
    align-items: flex-start;
  }
}

.create-playlist {
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  &:hover {
    background: none;
  }

  .button-create {
    padding: 10px;

    @media only screen and (max-width: $breakpoint_880) {
      border-bottom: 1px solid $border;
      border-radius: 25px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  input {
    padding: 0 5px;
    height: 30px;
    margin-top: 8px;
    margin-bottom: 3px;

    &:focus {
      padding: 0 5px;
    }

    @media only screen and (min-width: $breakpoint_658) {
      margin-left: 10px;
    }
  }

  .box-input-create {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;
  }
}

.modal-more-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  z-index: 1001;

  .icon-close-modal {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      width: 25px;
      display: inline-block;
    }
  }
}

.more-menu-mobile {
  height: 100%;
  position: relative;

  .add-modal {
    .box-list {
      max-height: unset;
      max-width: unset;
    }
  }

  li,
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start !important;
    color: $black;
    padding: 15px 16px !important;
    text-decoration: none;

    &:hover {
      background: transparent !important;
    }

    img {
      margin-right: 10px;
    }
  }
}

.track-info-menu {
  position: relative;
  height: 100%;
  overflow-y: auto;

  p {
    margin-bottom: 10px;
  }

  padding-bottom: 200px;
}

.add-modal-content {
  position: relative;
  height: 100%;
}

.add-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  z-index: 1002;
  color: $black;
  overflow: auto;

  .create-playlist {
    padding: 0px !important;
    display: block !important;

    &:hover {
      background: transparent !important;
    }
  }

  .header {
    text-align: center;
    color: $black;
  }

  .button-create {
    color: $white;
    background-color: $black;
    padding: 15px;
  }

  .box-input-create {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    input {
      margin-bottom: 0;
      height: 40px;
      width: 100%;
      padding: 25px 10px;
      border-radius: 25px;
    }

    img {
      margin: 0 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .box-list {
    li {
      padding: 15px;

      &:hover {
        background: transparent !important;
      }
    }
  }
}

.add-to-playlist {
  .create-playlist {
    .button-create {
      width: 100%;

      &:hover {
        background: rgba(20, 17, 64, 0.1);
      }
    }

    .box-list {
      li {
        &:hover {
          background: rgba(20, 17, 64, 0.1);
        }
      }
    }
  }
}