.shareAllButton {
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  padding: 0px 10px 0px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  align-self: center;
  &:hover svg {
    path {
      fill: black;
    }
  }
  &-copy {
    color: $grey_light;
  }
  .shareModal {
    visibility: hidden;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #141140;
    box-shadow: 0px 0px 8px rgba(20, 17, 64, 0.5);
    border-radius: 4px;
    z-index: 5;
    padding: 25px;
    cursor: default;

    @media only screen and (max-width: $breakpoint_880) {
      transform: translate(-50%, -15%);
    }
    @media only screen and (max-width: $breakpoint_518) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #ffffff;
      z-index: 1001;
      padding: 5px;
      padding-top: 100px;
      transform: none;
    }

    .closeIcon {
      display: flex;
      margin-left: auto;
      cursor: pointer;
    }

    .shareComponentColumn {
      display: grid;
      grid-template-columns: auto auto;
      padding: 10px;
    }

    &.visible {
      visibility: visible;
    }

    .shareAll-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #141140;
      padding-bottom: 20px;
      width: 100%;
      margin: auto;

      @media only screen and (max-width: $breakpoint_518) {
        padding-bottom: 10px;
      }
    }
  }
}

.shareComponent {
  font-family: "Roboto", sans-serif;
  display: flex;
  background: white;
  border: 1px solid #bbbfc7;
  border-radius: 100px;
  padding: 13px 26px;
  margin-bottom: 25px;
  cursor: pointer;
  margin: 10px 10px;
  width: 185px;
  justify-content: center;

  @media only screen and (max-width: $breakpoint_518) {
    width: 130px;
    padding: 10px 10px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    margin-right: 10px;
  }

  span,
  a {
    margin: auto 0px;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    color: inherit;

    @media only screen and (max-width: $breakpoint_518) {
      font-size: 12px;
    }
  }

  &:hover {
    background: rgba(64, 17, 17, 0.1);
  }
}
