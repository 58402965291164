.DiscoverVideos {
  margin-bottom: 200px;

  &__SectionTitle {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    margin-bottom: 48px;

    @media only screen and (max-width: $breakpoint_768) {
      margin-bottom: 20px;
    }
  }

  &__Container {

    li {
      background-color: white;
      width: 30%;
      padding: 16px;
      margin-bottom: 16px;
      position: relative;
      cursor: pointer;

      @media only screen and (max-width: $breakpoint_768) {
        width: 100%;
      }

      &:hover {
        box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
      }
      a {
        color: $black;
        &:hover {
          text-decoration: none;
        }
      }
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      .play-video {
        position: absolute;
        right: 24px;
        top: 160px;
        cursor: pointer;
        img {
          width: 48px;
          height: 48px;
        }
      }
    }
    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin: 16px 0 8px 0;
      word-break: break-word;
    }
    .desc {
      font-size: 16px;
      line-height: 26px;
    }
  }
}
