.library {
  .text-header {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 42px;
    color: $white;
    margin-bottom: 24px;
  }
  .subheader {
    font-size: 16px;
    line-height: 28px;
    color: $white;
    max-width: 350px;
  }
  .library-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 32px;
  }
  .library-box {
    width: 32%;
    margin-bottom: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $grey_vlight;
    border-radius: 8px;
    text-decoration: none;
    color: $black;
    cursor: pointer;
    img {
      margin-top: 33px;
      margin-bottom: 15px;
      height: 50px;
    }
    .title {
      text-align: center;
      padding: 0 5px;
      font-size: 1.2em;
    }
  }
  .coming {
    background: rgba(221, 78, 82, 0.1);
    border-radius: 2px 2px 0px 0px;
    padding: 2px 8px;
    color: $red;
    height: 22px;
    font-size: 14px;
    line-height: 16px;
    margin-top: 15px;
  }
  .coming-mask {
    margin-top: 15px;
    padding: 2px 8px;
    height: 22px;
    font-size: 14px;
    line-height: 16px;
  }

  .recently-saved {
    background: #f4f4f4;
    padding-top: 64px;
    padding-bottom: 64px;

    .header {
      font-family: "Playfair Display", serif;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
      padding-bottom: 24px;
    }
  }

  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: inset 0px -1px 0px #dcdfe4;
    justify-content: center;
    max-width: 1120px;
    margin: 0px auto;
    user-select: none;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-x: scroll;
    justify-content: flex-start;

    > a,
    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 72px;
      margin: 0 32px;
      color: $grey_light;
      font-family: "Playfair Display", serif;
      font-weight: 600;
      font-style: normal;
      font-size: 22px;
      position: relative;
      cursor: pointer;
      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
      animation: fadeIn 1s ease both;
      animation-iteration-count: 1;
      text-decoration: none;
      &.adjust {
        flex-shrink: 0;
        flex-basis: 100px;
      }
      &.active {
        color: $black;
        &:before {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          height: 3px;
          width: 100%;
          background: $red;
        }
      }

      @media only screen and (max-width: $breakpoint_880) {
        font-family: "Playfair Display", serif;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  .tabContainer {
    padding-bottom: 64px;

    .showTab {
      display: block;
    }

    .hideTab {
      display: none;
    }
  }
}
// Jazz
.library.jazz {
  .coming {
    background: rgba(72, 102, 224, 0.1);
    color: $blue;
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .library {
    &__header {
      margin-bottom: 16px;
    }
    .library-box {
      width: 48%;
      min-height: 143px;

      img {
        height: 30px;
      }
      .title {
        font-size: 1em;
      }
    }
  }
}
.header-create-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: $breakpoint_880) {
    justify-content: center;
  }
}

.header-create-button {
  @extend .shadow;
  background-color: $white;
  padding: 10px;
  width: max-content;
  position: relative;
}
