.partner-page {
  &.jazz {
    .partner-page__hero {
      .left-section {
        .partner-page__hero-banner-link,
        .partner-page__hero-banner-link:visited {
          color: $blue;
        }
      }
    }
  }
  &__hero {
    .left-section {
      .partner-page__hero-banner-link,
      .partner-page__hero-banner-link:visited {
        color: $red;
      }
    }
  }

  .page-header {
    .buttons {
      display: flex;
      justify-content: flex-start;
      @media only screen and (max-width: $breakpoint_880) {
        justify-content: center;
      }
    }
  }

  &__images-section {
    margin-bottom: 120px;
  }

  &__extras-section,
  &__top-section {
    .extra-item {
      max-width: 1120px;
      margin: 0px auto;
      padding: 25px 0px;
    }
  }
}
