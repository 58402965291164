.no-match,
.sitemap {
  min-height: 100vh;
  .container {
    background: $grey_mlight;
    display: flex;
    padding: 40px;
    position: relative;
  }
  .container-box {
    background: $white;
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 40px;
    &__left {
      margin-right: 45px;
    }
    &__right {
      display: flex;
      height: 100%;
      margin-right: 30px;
      img {
        display: block;
        height: 450px;
        object-fit: contain;
      }
    }
    .subheader {
      margin-bottom: 15px;
    }
    &__leftLogged {
      li {
        margin: 15px 0;
        a {
          color: $red;
          text-decoration: none;
        }
      }
      &.jazz {
        li {
          a {
            color: $blue;
          }
        }
      }
    }
    &__rightLogged {
      margin-left: 128px;
      .circle {
        width: 448px;
        height: 448px;
        border-radius: 50%;
        background: rgba(20, 17, 64, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 240px;
          height: 212px;
        }
      }
    }
    .header {
      max-width: 700px;
      font-size: 20px;
      line-height: 42px;
      margin-bottom: 50px;
    }
    .button-red,
    .button-blue {
      max-width: 280px;
    }
    .text {
      margin-top: 24px;
      span {
        margin-left: 5px;
        color: $red;
        cursor: pointer;
      }
      &.jazz {
        span {
          color: $blue;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_1024) {
  .no-match {
    .container-box {
      .header {
        font-size: 20px;
        line-height: 28px;
      }
      &__leftLogged {
        width: 50%;
      }
      &__rightLogged {
        width: 50%;
        margin-left: 40px;
        .circle {
          width: 348px;
          height: 348px;
          img {
            width: 200px;
            height: 177px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .no-match {
    .container-box {
      &__left,
      &__right {
        width: 50%;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .no-match {
    .container {
      padding: 16px;
    }
    .container-box {
      flex-direction: column;
      top: 16px;
      bottom: 16px;
      left: 16px;
      right: 16px;
      padding: 24px;
      &.container-box-logged {
        align-items: flex-start;
        justify-content: flex-start;
      }
      &__left {
        margin: 0 0 30px 0;
        width: 100%;
      }
      &__right {
        width: 100%;
        margin-right: 0;
        img {
          margin: 0 auto;
          width: 100%;
        }
      }
      .header {
        max-width: 100%;
      }
      &__leftLogged {
        width: 100%;
      }
      &__rightLogged {
        width: 100%;
        margin: 0;
        .circle {
          width: 124px;
          height: 124px;
          position: absolute;
          bottom: 24px;
          right: 24px;
          img {
            width: 69px;
            height: 61px;
          }
        }
      }
    }
  }
}

.no-match {
  .container {
    height: calc(100% - 68px);
  }
}
.sitemap {
  .container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .container-box {
    position: relative;
    width: 100%;
    left: unset;
    top: unset;
    right: unset;
    bottom: unset;
  }
  .col-3 {
    columns: 3 auto;
    @media only screen and (max-width: $breakpoint_658) {
      columns: 2 auto;
    }
    @media only screen and (max-width: $breakpoint_518) {
      columns: 1 auto;
    }
  }
  ul {
    position: relative;
    z-index: 1;
  }
  .circle {
    width: 448px;
    height: 448px;
    border-radius: 50%;
    background: rgba(20, 17, 64, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.3;
    z-index: 0;
    img {
      width: 240px;
      height: 212px;
    }
    @media only screen and (max-width: $breakpoint_1024) {
      width: 348px;
      height: 348px;
      img {
        width: 200px;
        height: 177px;
      }
    }

    @media only screen and (max-width: $breakpoint_768) {
      width: 124px;
      height: 124px;
      position: absolute;
      bottom: 24px;
      right: 24px;
      img {
        width: 69px;
        height: 61px;
      }
    }
  }
}
