@import "./constants.scss";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: $black;
}

body {
  font-family: "Roboto", sans-serif;
}

* {
  box-sizing: border-box;
}

#root,
.container-app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
  &.overflow {
    overflow: hidden;
  }
}

.bottom-spacer {
  padding-bottom: 90px;
}

.bottom-fixed {
  position: fixed;
  z-index: 10;
  bottom: 0px;
  left: 0;
  right: 0;
}

.loading-page {
  position: absolute;
  top: 68px;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  position: relative;
  input {
    background: rgba(255, 255, 255, 0);
    position: relative;
    z-index: 2;
  }
  .placeholder {
    position: absolute;
    z-index: 1;
    top: 19px;
    left: 16px;
    color: $border;
    transition: all 0.15s;
  }
  & + .error {
    position: relative;
    top: -10px;
  }
  &.error-input {
    .placeholder {
      color: $red_black;
    }
  }
}

input {
  height: 52px;
  border: 1px solid $border;
  padding: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  color: $black;
  outline: none;
  ::placeholder {
    color: $border;
  }
  &:focus,
  &.filled {
    padding-top: 34px;
  }
  &.filled + .placeholder,
  &:focus + .placeholder {
    top: 9px;
    font-size: 12px;
    z-index: 3;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.submit,
.button-red,
.button-white,
.button-blue {
  background: $red;
  &:hover {
    background: linear-gradient(0deg, rgba(20, 17, 64, 0.2), rgba(20, 17, 64, 0.2)), $red;
  }
}

.button-blue {
  background: $blue;
  &:hover {
    background: linear-gradient(0deg, rgba(20, 17, 64, 0.2), rgba(20, 17, 64, 0.2)), $blue;
  }
}

.viewport-block {
  &.useMinHeight {
    min-height: 200px;
  }
}

.submit,
.button-red,
.button-white,
.button-blue {
  border: none;
  border-radius: 100px;
  padding: 16px;
  height: 48px;
  text-align: center;
  color: $white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 8px;
  outline: none;
  line-height: 16px;
  transition: all 200ms;

  &.slim {
    height: unset;
    padding: 10px;
  }

  &.no-padding {
    padding: 0;
  }
}

.button-white {
  background: transparent;
  border: 1px solid $white;
  color: $white;
  &:hover {
    background: $white;
    color: $red;
  }
}

.button-red-outlined,
.button-blue-outlined,
.button-white-outlined,
.button-black-outlined {
  border: solid 1px;
  border-radius: 100px;
  padding: 16px;
  height: 48px;
  cursor: pointer;
  margin-top: 8px;
  outline: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  display: flex;
  transition: all 200ms;
  &:hover {
    background: rgb(241, 241, 241);
  }

  &.slim {
    height: unset;
    padding: 10px 5px;
  }
  &.mt-0 {
    margin-top: 0px;
  }
}

.button-white-outlined {
  background: $white;
  color: #8f99aa;
}

.button-red-outlined {
  background: $white;
  color: $red;
  &:hover {
    background: $red;
    color: $white;
  }
}

.button-blue-outlined {
  background: $white;
  color: $blue;
  &:hover {
    background: $blue;
    color: $white;
  }
}

.button-black-outlined {
  background: $white;
  color: $black;
  border-color: $black;
}

.text-bold {
  font-weight: bold;
}

.grey-fullwidth-container {
  background: #f4f4f4;
  padding: 0px 30px;
}

.divider-component {
  max-width: 1120px;
  min-height: 1px;
  width: 100%;

  &__top {
    border-bottom: 1px solid $black;
    &.border-grey {
      border-color: #e0e0e0;
    }
  }
}

.list-of-songs {
  padding: 64px 0;
  position: relative;
  @media only screen and (max-width: $breakpoint_880) {
    padding: 32px 0;
  }

  @media only screen and (max-width: $breakpoint_658) {
    padding: 32px 0;
    .time-text,
    .icon-time {
      display: none;
    }
    &__right {
      padding-left: 5px;
    }
  }
  .also-like {
    padding: 32px 0;
    &__box {
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  li {
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $black;
    border-radius: 4px;
    @media only screen and (max-width: $breakpoint_518) {
      padding: 16px 0px;
    }
    &.active {
      .track-title {
        color: $red;
      }
    }
    &:hover {
      background: rgba(221, 78, 82, 0.1);
      .index {
        span {
          display: none;
        }
        .icon-play-circle-red {
          display: block;
        }
      }
    }
    .icon-play-circle-red {
      width: 40px;
      height: 40px;
      display: none;
      cursor: pointer;
    }
  }
  &__left {
    cursor: pointer;
    padding-right: 15px;
  }
  &__left,
  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__right {
    color: $grey_light;
    line-height: 23px;
    padding-left: 35px;

    @media only screen and (max-width: $breakpoint_518) {
      padding-left: 0px;
    }
  }
  .index {
    line-height: 23px;
    color: $grey_light;
    margin-right: 10px;
    min-width: 40px;
    text-align: center;

    > img {
      margin: auto;
    }
  }
  .info-songs {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    justify-content: center;
    .row {
      display: flex;
      flex-direction: row;
    }
  }
  .composer {
    font-weight: bold;
    line-height: 23px;
    margin-right: 8px;
  }
  .track-title {
    line-height: 23px;
  }
  .performances {
    line-height: 23px;
    color: $grey_light;
    margin-top: 4px;
    > span,
    > div {
      > span {
        > a {
          color: inherit;
          text-decoration: none;
          &:visited,
          &:hover {
            text-decoration: underline;
            color: inherit;
          }
        }
      }
    }
  }
  .icon-time {
    margin-right: 5px;
  }
  .icon-more {
    margin-left: 18px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    .iconMore {
      width: 100%;
      height: auto;
    }
  }
}

.error {
  font-size: 12px;
  color: $red_black;
}

.content {
  max-width: 1120px;
  margin: 0 auto;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

img {
  display: block;
}

.header-txt {
  font-family: "Playfair Display", serif;
  line-height: normal;
  font-size: 42px;
  font-weight: 600;
  color: $black;
}

.ReactModalPortal {
  position: relative;
  z-index: 2147483005;
}

.back {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  line-height: 28px;
  color: $black;
  margin-bottom: 16px;
  margin-top: 16px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  img {
    margin-right: 10px;
  }
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &.long {
    -webkit-line-clamp: 7;
  }
  &.short {
    -webkit-line-clamp: 3;
  }
  &.tiny {
    -webkit-line-clamp: 2;
    &-landscape {
      @media only screen and (max-width: $breakpoint_880) and (orientation: landscape) {
        -webkit-line-clamp: 1;
      }
    }
  }
}
.list-of-songs {
  .iconHeart {
    @extend .mc-heart;
  }
}
.read-more {
  max-height: 150px;
  @media only screen and (max-width: $breakpoint_518) {
    max-height: 120px;
  }
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

  &.open {
    max-height: 100rem;
    transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);

    animation: truncate-open 0.1s linear 0s forwards;
    @media only screen and (max-width: $breakpoint_518) {
      animation: truncate-open-mobile 0.1s linear 0s forwards;
    }
    display: block;
  }

  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 23px;
  color: inherit;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 12px 0;
  animation: truncate-close 0.1s linear 0.1s forwards;
  @media only screen and (max-width: $breakpoint_518) {
    animation: truncate-close-mobile 0.1s linear 0.1s forwards;
  }
  > a {
    color: inherit;
  }
}

@media only screen and (max-width: $breakpoint_1200) {
  .content {
    max-width: 100%;
    padding: 0 30px;
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .back {
    margin-bottom: 22px;
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .content {
    padding: 0 16px;
  }
  .header-txt {
    font-size: 32px;
  }
  .ReactModalPortal {
    z-index: 2147483005;
  }
  .ReactModal__Content {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 !important;
    transform: none !important;
    display: flex;
    align-items: center;
  }
}

.cc-revoke.cc-bottom {
  display: none;
}

.confidentiality {
  .terms-modal {
    padding: 30px 0;
    max-width: 100%;
  }
}

.loading-redirect {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-screen {
  width: 100%;
  top: 45vh;
  position: relative;
}

.loading-icon-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-screen,
.loading-icon-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-gallery {
  margin: auto;
  width: 600px;

  @media only screen and (max-width: $breakpoint_880) {
    width: 100%;
  }

  .image-gallery-icon {
    &.image-gallery-fullscreen-button {
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .image-gallery-slide {
    .image-gallery-description {
      bottom: 0px;
      font-size: 10px;
      padding: 5px 10px;
    }
  }

  .image-gallery-fullscreen-button {
    height: 80px;
    top: 20px;

    @media only screen and (max-width: 1120px) {
      top: 0px;
    }
  }

  .thumbnail {
    &.hide {
      display: none;
    }
    vertical-align: middle;
    cursor: pointer;
  }
}

.post-loading {
  width: 500px;
  height: 30px;
  /* background-color: red; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 25px;
  text-align: center;
  span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }
}

.hover-darken {
  &:hover,
  &:active {
    background-color: $greyLight;
  }
}

.hover-shadow {
  transition: box-shadow 0.2s ease-out;

  &:hover {
    text-decoration: none;
    box-shadow: 0 5px 5px rgba(#000, 0.4);
  }
  &:active {
    box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
  }
}

.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 200ms ease-in-out;
  background: rgba(0, 0, 0, 0.15);
  &--after-open {
    opacity: 1;
  }
  &--before-close {
    opacity: 0;
  }
}

.play-icon-extend {
  opacity: 0;
  transition: all 200ms ease-in-out;

  &:hover {
    transform: scale(1.5);
  }

  &.loading,
  &.playing,
  &.loading img,
  &.playing img {
    opacity: 1;
    width: 48px !important;
    height: 48px !important;
  }

  &.loading {
    &:before {
      content: "";
      position: absolute;
      left: 4px;
      top: 4px;
      width: 30px;
      height: 30px;
      border-color: transparent #f4f4f4 transparent transparent;
      border-style: solid;
      border-width: 5px;
      border-radius: 50%;
      -webkit-animation-name: rotate;
      animation-name: rotate;
      -webkit-animation: rotate 1s ease;
      animation: rotate 1s ease;
      animation-iteration-count: 1;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }
  }
}

.track-disabled {
  cursor: default;
  background-color: $white;

  &:hover {
    background-color: $white !important;
  }

  .track-disabled-icon {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .list-of-songs__left {
    cursor: default;
  }

  &.recommend-container .name,
  .track-title,
  .work-name,
  .composer-text,
  .list-tracks__left,
  &.box-work .info-row,
  &.recordings-list-item,
  .track-name {
    position: relative;
    padding-right: 20px;
    color: $grey_dark;
  }

  &.box-work .box-work__left span {
    color: $grey_dark;
  }

  &.recommend-container .name,
  .work-name,
  .list-of-queue__info-track > .composer-text,
  .info-songs .track-title,
  .list-tracks__left > div,
  &.box-work .info-row,
  .track-name {
    &:after {
      background-image: url("../assets/images/geo-icon.svg");
      width: 20px;
      height: 20px;
      top: 0px;
      right: -3px;
      position: absolute;
      content: "";
      display: inline-block;
    }
  }

  .list-of-queue__info-track > .composer-text,
  .box-work .row.info-row,
  .list-tracks__left > div {
    &:after {
      top: 3px;
      right: -5px;
      position: relative;
    }
  }
}

// Crisp floating icon
#crisp-chatbox > div > a {
  margin-bottom: unset !important;
  bottom: 162px !important;
}

.no-link {
  cursor: default !important;
  text-decoration: none !important;
}

.has-link {
  cursor: pointer;
  color: $black;

  &.red,
  &.classical {
    color: $red !important;
    text-decoration-color: $red;
  }
  &.blue,
  &.jazz {
    color: $blue !important;
    text-decoration-color: $blue;
  }
  &:visited,
  &:hover {
    color: initial;
    text-decoration: underline;
  }
  &.no-underline {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  &.underline {
    text-decoration: underline;
  }
}

.pagination-wrapper {
  text-align: center;
  margin: 20px 0px;

  .pagination-url {
    text-decoration: none;
    color: grey;
    font-size: 18px;
    margin: 0px 5px;

    &.active {
      font-size: 20px;
      font-weight: 700;
      color: black;
    }
  }
  .pagination-dots {
    color: inherit;
    margin: 0px 0px;
  }
}

.typeform-quiz {
  height: 100%;
  width: 100%;
}

.pill {
  border-radius: 40px;
  width: max-content;
  max-width: 300px;
  padding: 7px 15px 7px 7px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 10px 10px 10px 0px;
  .image-container {
    margin-right: 10px;
    overflow: hidden;
  }
  &.square {
    border-radius: 5px;
    .image-container {
      border-radius: 5px;
    }
  }
  color: $black;
  &:visited {
    color: $black;
  }
}

.overlayResp {
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(10px);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  &.mobile {
    background-position: center;
    opacity: 0.2;
  }
}

.nowrap {
  white-space: nowrap;
}

.thin-scrollbars {
  scrollbar-color: $grey_dark $greyLight;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $grey_light;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $grey_dark;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $grey_dark;
  }
}
