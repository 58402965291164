.work-details {
  .error {
    text-align: center;
    font-size: 16px;
    margin-top: 50px;
  }
  .header-work {
    background: $grey_mlight;
    margin-bottom: 40px;
    .content {
      padding-top: 52px;
      padding-bottom: 52px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    img {
      border-radius: 50%;
      min-width: 96px;
      min-height: 96px;
      max-width: 96px;
      max-height: 96px;
      margin-right: 24px;
    }
    .title {
      font-family: 'Playfair Display', serif;
      font-weight: 600;    
      font-style: normal;
      font-size: 22px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .composer-name {
      line-height: 23px;
      margin-bottom: 5px;
    }
    .year {
      color: $grey_light;
      line-height: 23px;
    }
  }
  .list-works {
    max-width: 940px;
    padding: 0 30px;
    margin: 0 auto;
    .head {
      font-size: 20px;
      line-height: 35px;
      margin-bottom: 40px;
    }
  }
  .head-list-works {
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .button-red {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 160px;
    min-width: 160px;
    height: 40px;
    margin-top: 0px;
    img {
      margin-right: 10px;
    }
  }
  .button-sort {
    border: 1px solid $black;
    padding: 0 8px;
    border-radius: 8px;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
  .box-sort {
    background: $white;
    border: 1px solid $black;
    box-shadow: 0px 4px 6px $grey_vlight;
    border-radius: 8px;
    position: absolute;
    z-index: 2;
    padding: 6px 20px;
    min-width: 240px;
    top: 44px;
    right: 0;
    .option {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      line-height: 23px;
      cursor: pointer;
      &.active {
        .descend {
          opacity: 1;
        }
      }
      .descend {
        opacity: .3;
      }
      img {
        margin: 0;
      }
      &__left, &__right {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &__left {
        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
    }
  }
}
.list-of-work-tracks {
  margin-bottom: 50px;
  li {
    line-height: 23px;
    border-radius: 4px;
  }
  .box-work {
    border-radius: 4px;
    &:hover {
      background: rgba(221, 78, 82, 0.1);
    }
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &__left {
      cursor: pointer;
    }
    &__left, &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .icon-play,
    .icon-pause {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      margin-right: 15px;
      cursor: pointer;
    }
    .info-row {
      line-height: 23px;
      color: $black;
      font-weight: bold;
    }
    .number-tracks {
      color: $grey_light;
      line-height: 23px;
    }
  }
  .icon-more {
    margin-right: 18px;
  }
  .iconMore,
  .icon-arrow-down,
  .icon-arrow-up {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}
.list-tracks {
  margin-bottom: 25px;
  display: none;
  &.active {
    display: block;
  }
  .iconHeart {
    max-height: 30px;
    max-width: 30px;
    img {
      max-height: 30px;
      max-width: 30px;
    }
  }
  li {
    border-radius: 4px;
    padding: 10px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    line-height: 23px;
    &:hover {
      background: rgba(221, 78, 82, 0.1);
    }
    img {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
  }
  &__left {
    cursor: pointer;
  }
  &__left, &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .icon-more {
    margin-right: 34px;
    .more-menu {
      right: 18px;
    }
  }
  .icon-play,
  .icon-pause {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    cursor: pointer;
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .work-details {
    .list-works {
      padding-left: 16px;
      padding-right: 16px;
    }
    .list-of-work-tracks {
      .box-work {
        padding-left: 0;
        padding-right: 0;
        &:hover {
          background: transparent;
        }
        &__left {
          padding-right: 10px;
        }
      }
      .icon-play, .icon-pause {
        display: none;
      }
    }
    .list-tracks {
      li {
        padding-right: 0;
      }
      &__left {
        padding-right: 10px;
      }
    }
  }
}
