.modal-content {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  @media only screen and (min-width: $breakpoint_518) {
    padding: 40px;
  }
  & .button-red {
    text-decoration: none;
    transition: all 0.1s;
    img {
      display: none;
    }
  }
  & .quiz-back {
    display: inline-flex;
    position: fixed;
    bottom: 5px;
    left: 10px;
    cursor: pointer;
    font-size: 16px;
    line-height: 30px;
    padding: 10px 0;
    &:hover {
      text-decoration: none;
    }
  }
  .header {
    padding: 20px;
    @media only screen and (max-width: $breakpoint_518) {
      padding: 0;
    }
    .title,
    .text {
      font-size: $size_24;
      @media only screen and (max-width: $breakpoint_518) {
        font-size: 24px;
      }
    }
    .sub-title,
    .subtext {
      font-size: $size_20;
      @media only screen and (max-width: $breakpoint_518) {
        font-size: $size_16;
      }
    }
    .title,
    .sub-title,
    .text,
    .description,
    .subtext {
      margin-bottom: 10px;
      line-height: 20px;
    }
  }
  .body {
    max-width: 800px;
    margin: auto;
    width: 100%;
  }
  & > .modal-footer {
    background-color: transparent;
    display: block;
    margin-bottom: 10px;
  }
  .button-red {
    display: inline-flex;
    justify-content: center;
    min-width: 200px;
    margin-bottom: 10px;
    &:hover {
      background-color: $red_black;
    }
    &.jazz {
      background-color: $blue;
      &:hover {
        background-color: $blue_dark;
      }
    }
    &.disabled {
      pointer-events: none;
      cursor: pointer;
      opacity: 0.7;
    }
  }
  .final-page-container,
  .questions-container,
  .options-container {
    overflow: hidden;
  }

  .options-container {
    float: left;
    display: inline-flex;
    justify-content: space-evenly;
    flex-direction: row;
    &.stack {
      &.many-children {
        .option {
          &.circle-option {
            .img-container {
              width: 120px;
              height: 120px;
            }
          }
        }
      }
      //small screens
      @media only screen and (max-width: $breakpoint_518) {
        flex-direction: column;
        &.many-children {
          .option {
            &.circle-option {
              .img-container {
                width: 100px;
                height: 100px;
              }
            }
          }
        }
        .option {
          &.circle-option {
            display: flex;
            flex-direction: row;
            width: 100%;
            .img-container {
              margin: 1%;
            }

            .text-container {
              text-align: left;
              margin-top: auto;
              margin-bottom: auto;
              padding: 10px;
              flex: 5;
              .text {
                width: 100%;
              }
            }
          }
          &.square-option {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin: 10px 0;
            .img-container,
            .text-container {
              max-width: unset;
            }
            .img-container {
              margin: 0;
              width: 100%;
              img {
                top: -50px;
                position: relative;
              }
              .text-container {
                bottom: unset;
                top: 0;
                left: 0;
                display: block;
                .description {
                  display: none;
                }
              }
            }
            .text-container {
              display: none;
              text-align: left;
              margin-top: auto;
              margin-bottom: auto;
              padding: 10px;
              color: $white;
              .text {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .option {
      display: flex;
      flex-direction: column;
      margin: 0 5px;
      padding: 5px;
      cursor: pointer;
      flex: 1;
      @media only screen and (max-width: $breakpoint_518) {
        padding: 0;
      }

      &.circle-option {
        &.selected {
          .img-container {
            box-shadow: 0px 4px 10px rgba(143, 153, 170, 1);
          }
        }
        .img-container {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          overflow: hidden;
          display: block;
          margin: 0 auto 10px;
          border: 5px solid white;
          @extend .hover-shadow;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &.square-option {
        border-radius: 20px;
        text-align: left;
        padding: 0px;
        border: 1px solid $grey_vlight;

        @extend .hover-shadow;

        .img-container {
          position: relative;
          img {
            filter: brightness(70%);
          }
          .text-container {
            position: absolute;
            bottom: 0;
            left: 0;
            color: $white;
            .text {
              font-family: "Playfair Display", serif !important;
              font-size: $size_18;
            }
            .description {
              display: block;
            }
          }
        }
        //bigger screens
        @media only screen and (min-width: $breakpoint_518) {
          margin-left: 20px;
          margin-right: 20px;
        }
        &.selected {
          box-shadow: 0px 4px 10px rgba(143, 153, 170, 1);
        }
        .img-container,
        .text-container {
          max-width: 200px;
        }
        .img-container {
          height: 150px;
          border-radius: 20px;
          overflow: hidden;
          display: block;
          margin: 0 auto 10px;
          border: 5px solid white;
          img {
            width: 100%;
            height: auto;
          }
          .text-container {
            padding: 20px;
            .description {
              display: none;
            }
          }
        }
        .text-container {
          padding: 0 15px 15px;
          color: $grey_dark;
        }
      }

      .text-container {
        .text {
          width: 100%;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .options-container {
    &.active {
      margin: 10px 0;
      width: 100%;
      padding: 13px;
    }
  }
  .final-page-container,
  .options-container,
  .question {
    max-height: 0;
    max-width: 0;
    opacity: 0;

    &.active {
      opacity: 1;
      animation: createBox 2s;
      animation-timing-function: ease-in-out;
      -webkit-animation-timing-function: ease-in-out;
      max-height: 1000px;
      max-width: 1000px;
    }
  }
  .final-page-container,
  .question {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .final-page-container {
    .content {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 0;

      .NewsTile {
        border-radius: 20px;
        width: 50%;
        overflow: hidden;
        max-height: 250px;
        max-width: 250px;
        .picture-wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          .play-video-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -40px;
            margin-left: -40px;
            width: 80px;
            height: 80px;
          }
          .picture {
            height: 100%;
            object-fit: cover;
            position: relative;
            width: 100%;
          }
        }
      }
      @media only screen and (max-width: $breakpoint_518) {
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        .NewsTile {
          width: 100%;
        }
      }
      .header {
        text-align: left;
        width: 50%;
        .text {
          font-size: $size_18;
          margin-bottom: 10px;
        }
        .description {
          font-size: $size_16;
          color: $grey_dark;
        }
        .NewsTile {
          border-radius: 20px;
          width: 50%;
        }
        @media only screen and (max-width: $breakpoint_518) {
          text-align: center;
          margin: 30px auto -30px;
          z-index: 3;
          width: 100%;

          .button-red {
            min-width: unset;
            width: 60%;
            img {
              display: block;
              margin-right: 10px;
            }
          }
          .text {
            font-size: $size_16;
          }
          .description {
            font-size: $size_16;
          }
        }
      }
    }
    & > .modal-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      @media only screen and (max-width: $breakpoint_518) {
        flex-direction: column;
      }
      .button-red {
        color: $red;
        border: 1px solid;
        border-color: $red;
        background-color: transparent;
        &:hover {
          border: 1px solid transparent;
          background-color: $red;
          color: $white;
        }
        &.jazz {
          color: $blue;
          border-color: $blue;
          &:hover {
            background-color: $blue;
            color: $white;
          }
        }
      }
    }
  }
}
