@import "./constants.scss";

.free-trial-box {
  width: 448px;
  background: $white;
  padding: 40px 30px;
  .icon-close {
    display: none;
  }
  .header-trial {
    line-height: 34px;
    font-size: 24px;
    color: $black;
    margin-bottom: 24px;
  }
  .subtitle {
    margin-bottom: 24px;
  }
  .points {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    .point {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
  }
  input,
  .submit {
    width: 100%;
  }
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading-icon {
    position: absolute;
  }
  .login {
    font-size: 16px;
    margin-top: 24px;
    span {
      margin-left: 5px;
      color: $red;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.terms {
  margin-top: 18px;
  color: $grey_light;
  line-height: 17px;
  font-size: 12px;
  span {
    color: $red;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}
// Jazz
.jazz-page.free-trial-box {
  .submit {
    background: $blue;
  }
  .login {
    span {
      color: $blue;
    }
  }
  .terms {
    span {
      color: $blue;
    }
  }
}

@media only screen and (max-width: $breakpoint_1024) {
  .free-trial-box {
    width: 100%;
    .header-trial {
      line-height: 30px;
      font-size: 21px;
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .free-trial-box {
    padding: 30px;
    .header-trial {
      line-height: 26px;
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .free-trial-box {
    padding: 20px;
    .header-trial {
      line-height: 25px;
      font-size: 17px;
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .ReactModalPortal {
    .free-trial-box {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .header-trial {
        line-height: 30px;
        font-size: 20px;
        margin-bottom: 15px;
        br {
          display: none;
        }
      }
      .icon-close {
        display: block;
        position: absolute;
        top: 16px;
        right: 16px;
      }
    }
  }
}
