.side-widget {
  width: 38px;
  position: fixed;
  left: 0px;
  top: 58%;
  z-index: 200;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  padding: 10px 0px;
  border-radius: 10px;
  background: #fff0e1;
  background-image: url("../assets/images/Christmas/background_stars.png");
  background-size: 220px;
  background-position: center;
  &.hidden {
    transform: translateX(-32px);
  }

  &:hover {
    box-shadow: 0 0 35px 2px rgb(0 0 0 / 24%);
  }

  .container {
    align-self: center;
    position: relative;

    .text {
      writing-mode: tb-rl;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      white-space: nowrap;
      text-transform: uppercase;
      font-weight: bolder;
      font-size: 15px;
      border-radius: 10px;
      margin: auto;
      width: 100%;
      display: flex;
      align-items: center;
    }

    .icon {
      padding-top: 10px;
    }
  }

  .x-button {
    width: 16px;
    height: 16px;
    background: sandybrown;
    position: absolute;
    top: -11px;
    left: 38px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50px;
    font-size: 11px;
    color: white;

    &:after {
      display: flex;
      content: "\00d7"; /* This will render the 'X' */
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &.hidden {
      &:after {
        content: "\003E"; /* This will render the '>' */
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 16px;
      }
    }
  }
}
