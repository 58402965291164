.Content-Slider {
  .slider-component {
    .slick-track {
      display: flex;
      align-items: stretch;
      padding: 10px 0;
    }

    .slick-slide {
      display: flex;
      align-self: stretch;
      height: unset;
      > div {
        display: flex;
        align-self: stretch;
        width: 100%;
      }

      .contents-wrapper {
        height: 100%;
      }
    }
  }

  &__Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 0px;
    border-top-style: solid;
    border-width: 1px;

    &.no-divider {
      border-top-style: none;
      border-width: 0px;
    }

    &__Title {
      font-family: "Playfair Display", serif;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      padding-bottom: 35px;

      @media only screen and (max-width: $breakpoint_1200) {
        font-size: 24px;
        line-height: 29px;
      }
    }

    &__ArrowWrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      img {
        padding-left: 30px;
        cursor: pointer;
      }
    }
  }
}
