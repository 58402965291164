.videos{
  &.jazz{
    .list-of-videos{
      margin-top: 56px;
      margin-bottom: 56px;
    }
  }
}
.loading-videos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}

.featured-videos {
  border: 1px solid $grey_vlight;
  border-radius: 8px;
  padding: 40px 16px 16px 16px;
  margin-top: 56px;
  margin-bottom: 56px;
  position: relative;
  display: flex;
  flex-direction: row;
  color: $black;
  &:hover {
    text-decoration: none;
  }
  .label {
    position: absolute;
    top: 0;
    left: 16px;
    background: $red;
    border-radius: 0px 0px 4px 4px;
    color: $white;
    font-size: 14px;
    padding: 5px 8px;
  }
  &__left {
    width: 57%;
    position: relative;
    img {
      cursor: pointer;
    }
    .mock {
      width: 100%;
    }
  }
  &__right {
    width: 43%;
    padding-left: 16px;
  }
  .play-video {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
    width: 80px;
    height: 80px;
  }
  .title-video {
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    margin-bottom: 10px;
  }
  .desc-video {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 16px;
  }
  .label-video {
    font-size: 14px;
    line-height: 20px;
    color: $grey_light;
  }
  .value-video {
    line-height: 23px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.list-of-videos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
  li {
    width: 30%;
    padding: 16px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
    }
    a {
      color: $black;
      &:hover {
        text-decoration: none;
      }
    }
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    .play-video {
      position: absolute;
      right: 24px;
      top: 160px;
      cursor: pointer;
      img {
        width: 48px;
        height: 48px;
      }
    }
  }
  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin: 16px 0 8px 0;
  }
  .desc {
    font-size: 16px;
    line-height: 26px;
  }
}
.time {
  display: flex;
  align-items: center;
  color: $grey_light;
  margin-top: 20px;
  font-size: 14px;
  img {
    margin-right: 5px;
  }
}

@media only screen and (max-width: $breakpoint_1200) {
  .list-of-videos {
    li {
      width: 32%;
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .featured-videos {
    &__left {
      width: 60%;
    }
    &__right {
      width: 40%;
    }
    .desc-video,
    .label-video,
    .value-video {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .list-of-videos {
    li {
      width: 49%;
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .featured-videos {
    flex-direction: column;
    margin-bottom: 16px;
    &__left {
      width: 100%;
      padding: 0;
      margin-bottom: 16px;
    }
    &__right {
      width: 100%;
      padding: 0;
    }
  }
  .list-of-videos {
    li {
      width: 100%;
    }
  }
}
