@import "./constants.scss";

.login-modal {
  width: 448px;
  background: $white;
  padding: 40px;
  position: relative;

  .header-login {
    font-size: 32px;
    line-height: 45px;
    color: $black;

    &.space-bottom {
      margin-bottom: 24px;
    }
  }

  input,
  .submit {
    width: 100%;
  }

  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading-icon {
    position: absolute;
  }

  .cta-wrapper {
    display: flex;
    flex-direction: column;
  }

  .cta {
    margin: 10px 0 6px 0;
    display: inline-block;
    color: $red;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .icon-close {
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
  }

  .no-account {
    margin-top: 24px;

    span {
      margin-left: 5px;
      color: $red;
      cursor: pointer;
    }
  }
}

.crm-iframe {
  width: 100%;
  height: 300px;
}

// Jazz
.jazz-page.login-modal {
  .forgot-password {
    color: $blue;
  }

  .submit {
    background: $blue;
  }

  .no-account span {
    color: $blue;
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .login-modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;

    .icon-close {
      top: 16px;
      right: 16px;
    }

    &.static {
      position: relative;
    }
  }
}