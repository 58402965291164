@import "./constants.scss";

.welcome {
  background: $grey_mlight;
  padding: 40px;
  min-height: 100%;
  display: flex;
  .loading-icon {
    position: absolute;
    display: flex;
    justify-content: center;
  }
  &__content {
    background: $white;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;

    &.full-height {
      height: 100vh;
    }
    &.jazz {
      .button-red {
        background: $blue;
      }
    }
  }
  &__logo {
    margin-bottom: 40px;
    text-align: center;
    img {
      display: inline-block;
    }
  }
  &__header {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 42px;
    margin-bottom: 30px;
  }
  &__subheader {
    line-height: 42px;
    font-size: 24px;
    max-width: 530px;
    text-align: center;
    margin: 0 auto;
  }
  .icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 50px 0;
    &__item {
      max-width: 220px;
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      line-height: 28px;
      img {
        margin-bottom: 20px;
      }
    }
  }
  .button-red {
    width: 280px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
    &.button-red-margin {
      margin-top: 20px;
    }
    &.jazz {
      background: $blue;
    }
  }
  .box-composer {
    background: $white;
    box-shadow: 0px 0px 8px $grey_vlight;
    border-radius: 8px;
    padding: 40px;
    margin-top: 40px;
    text-align: center;
    display: inline-block;
    img {
      margin-bottom: 24px;
      display: inline-block;
    }
    .name {
      font-family: "Playfair Display", serif;
      line-height: normal;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .track {
      line-height: 23px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  .free-trial-text {
    font-size: 14px;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      margin: 0 5px;
      color: $red;
      cursor: pointer;
    }
  }

  &__skip {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;

    & a {
      color: $red;
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .welcome {
    padding: 20px;
    .icons {
      &__item {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .welcome {
    padding: 16px;
    &__content {
      padding: 10px;
      width: 100%;
    }
    &__header {
      font-size: 28px;
      word-break: break-word;
    }
    &__subheader {
      line-height: 24px;
      font-size: 15px;
    }
    .icons {
      &__item {
        img {
          max-width: 100%;
        }
      }
      &__item-text {
        display: none;
      }
    }
    .box-composer {
      padding: 24px;
      margin-left: 6px;
      margin-right: 6px;
    }
    .button-red {
      width: auto;
      height: 40px;
      padding-left: 30px;
      padding-right: 30px;
    }

    &__skip {
      display: block;
      margin-top: 25px;
      padding: 0px;
    }
  }
}

.expired-page {
  background: url("../assets/images/bg_notes.svg") no-repeat;
  background-size: cover;
  margin-bottom: 0;
  padding-top: 25px;
  padding-bottom: 200px;
  &-perk {
    max-width: 220px;
    line-height: 24px;
    img {
      max-height: 40px;
      @extend .mb-15;
    }
  }
}
