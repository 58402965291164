header {
  display: flex;
  justify-content: space-between;
  height: 20px;
  width: 100%;
}
.bar {
  ul {
    display: flex;
    flex-direction: row;
  }
  li {
    margin: 0 10px;
    text-transform: uppercase;
    a, span {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
}